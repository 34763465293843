import * as React from 'react';
import { ISubscriptionsMinicartResources } from '../subscriptions-minicart.props.autogenerated';
export interface ISubscriptionBannerDisplayProps {
    resources: ISubscriptionsMinicartResources;
    subscriptionName: string;
}

/**
 * Wrapper component for the subscription banner display
 */
export class SubscriptionBannerDisplayComponent extends React.Component<ISubscriptionBannerDisplayProps> {
    public render(): JSX.Element {
        const { subscriptionName } = this.props;
        return (
            <div className='subscription-minicart-title'>
                <div className='subscription-minicart-title-icon' />
                {subscriptionName && <h2 className='subscription-minicart-title-text'>{subscriptionName}</h2>}
            </div>
        );
    }
}