import debounce from 'lodash/debounce';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Modal, ModalBody, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { IWishlistActionSuccessResult } from '@msdyn365-commerce/components';
import { RichTextComponent } from '@msdyn365-commerce/core';
import { AttributeValue, CartLine, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { publish } from '../../../Utilities/analytics/analytics-dispatcher';
import { calculateSavingPercentAmount } from '../../../Utilities/elicit-savings-calculation';
import { filterCartLines, getSubscriptionValues } from '../../../Utilities/subscription-manager';
import { ElicitCartLineItems, IElicitCartLineItemsProps } from '../../elicit-cart/components/elicit-cart-line-items';
import { ElicitSubscriptionHeader, IElicitSubscriptionHeaderProps } from '../../elicit-cart/components/elicit-subscription-header';

import { ISubscriptionsMinicartData } from '../subscriptions-minicart.data';
import { IFrequencyTableData, ISubscriptionsMinicartProps } from '../subscriptions-minicart.props.autogenerated';

export interface IBoxEditComponentProps {
    initialValues: {
        name?: string;
        frequencyId?: string;
    };
    props: ISubscriptionsMinicartProps<ISubscriptionsMinicartData>;
    onBoxNameChange(newName: string): void;
    onFrequencyChange(newFrequency: IFrequencyTableData): void;
}

/**
 * declaration for the component that manages the editing
 * of the minicart when a user decides to name their box or
 * change their frequency
 */
@observer
export class BoxEditComponent extends React.Component<IBoxEditComponentProps> {

    @observable private _popupState: boolean = false;
    @observable private _name: string;
    @observable private _selectedFrequencyId: string;

    constructor(props: IBoxEditComponentProps) {
        super(props);

        this._onBoxNameChange = this._onBoxNameChange.bind(this);
        this._publishBoxNameChange = debounce(this._publishBoxNameChange.bind(this), 500);
        this._onFrequencyChange = this._onFrequencyChange.bind(this);
        this._togglePopup = this._togglePopup.bind(this);
        this._emptyCart = this._emptyCart.bind(this);

        const { name, frequencyId } = this.props.initialValues;
        this._name = name || '';
        this._selectedFrequencyId = frequencyId || props.props.config.frequencyTable?.find((freq) => !!freq.id)!.id!;

        reaction(
            () => { return this._popupState; },
            async () => {
                // tslint:disable-next-line: no-floating-promises
                await this._getProductavailabilty(this.props.props.data.products.result);
            }
        );
    }

    // tslint:disable-next-line:cyclomatic-complexity max-func-body-length
    public render(): JSX.Element | null {
        const {
            resources: {
                subscriptionsMinicart__editBoxBtn,
                subscriptionsMinicart__editBoxNamePlaceholder,
                subscriptionsMinicart_subscriptionSavingText,
                subscriptionsMinicart_subscriptionLinePercentSavingText,
                subscriptionsMinicart_emptyCartBtn
            }, config: { faqLink }
        } = this.props.props;

        const contextProps = this.props.props;
        const resources = contextProps.resources;
        const cart = contextProps.data.cart.result;
        const products = contextProps.data.products.result;
        const productAvailabilities = contextProps.data.productAvailabilites.result;
        const productDeliveryOptions = contextProps.data.deliveryOptions.result;
        const storeSelectorStateManager = contextProps.data.storeSelectorStateManager.result;
        const orgUnitLocations = contextProps.data.orgUnitLocations.result;

        if (!cart || !products || !productAvailabilities || !productDeliveryOptions || !storeSelectorStateManager || !orgUnitLocations) {
            return null;
        }

        const elicitModalProps: IElicitCartLineItemsProps = {
            percentSaving: calculateSavingPercentAmount(this._cartFilteredLines.subscriptionLines),
            templatePercentSavingText: subscriptionsMinicart_subscriptionLinePercentSavingText,
            templateSavingText: subscriptionsMinicart_subscriptionSavingText,
            cartlines: this._cartFilteredLines.subscriptionLines,
            cartLineErrors: {},
            mixedCart: {},
            mixedCartLines: cart.cart.CartLines|| [],
            products: products,
            productAvailabilites: productAvailabilities,
            productDeliveryOptions: productDeliveryOptions,
            imageSettings: contextProps.config.imageSettings || {
                viewports: {
                    xs: { q: `w=80&h=94&m=6`, w: 0, h: 0 },
                    sm: { q: `w=148&h=174&m=6`, w: 0, h: 0 },
                    lg: { q: `w=148&h=174&m=6`, w: 0, h: 0 }
                },
                lazyload: true
            },
            outOfStockThreshold: contextProps.context.app.config.outOfStockThreshold || 10,
            isStockCheckEnabled: contextProps.app.config.enableStockCheck || false,
            maxCartlineQuantity: contextProps.app.config.maxQuantityForCartLineItem,
            gridSettings: contextProps.context.request.gridSettings!,
            context: contextProps.context,
            typeName: contextProps.typeName,
            id: contextProps.id,
            resources: {
                sizeString: resources.subscriptionsMinicart__productDimensionTypeSize,
                colorString: resources.subscriptionsMinicart__productDimensionTypeColor,
                configString: 'asd',
                styleString: resources.subscriptionsMinicart__productDimensionTypeStyle,
                quantityDisplayString: resources.subscriptionsMinicart__quantityDisplayText,
                inputQuantityAriaLabel: resources.subscriptionsMinicart__inputQuantityAriaLabel,
                discountStringText: resources.subscriptionsMinicart__discountStringText,

                originalPriceText: resources.subscriptionsMinicart__originalPriceText,
                currentPriceText: resources.subscriptionsMinicart__currentPriceText,
                shippingChargesText: resources.subscriptionsMinicart__shippingCharges,
            },
            telemetry: contextProps.telemetry,
            addToWishlistButtonText: resources.subscriptionsMinicart__addToWishlistButtonText,
            removeFromWishlistButtonText: resources.subscriptionsMinicart__removeFromWishlistButtonText,
            removeButtonText: resources.subscriptionsMinicart__removeCartButtonText,
            shipItText: resources.subscriptionsMinicart__shipInsteadDisplayText,
            pickitUpText: resources.subscriptionsMinicart__pickItUpDisplayText,
            changeStoreText: resources.subscriptionsMinicart__changeStoreDisplayText,
            storeSelectorStateManager: storeSelectorStateManager,
            cartState: cart,
            orgUnitLocations: orgUnitLocations,
            wishlists: [],
            defaultWishlistName: resources.subscriptionsMinicart__defaultWishlistName,
            pickupDeliveryModeCode: contextProps.context.request.channel?.PickupDeliveryModeCode!,
            showImages: contextProps.config.showImages,
            invalidProductError: resources.subscriptionsMinicart__invalidProductError,
            outOfStockError: resources.subscriptionsMinicart__outOfStockError,
            zeroAvailabilityError: resources.subscriptionsMinicart__zeroAvailabilityError,
            showDiscountPercent: contextProps.config.showDiscountPercent,
            removeItemClickHandler: this._removeItemFromCartHandler,
            moveToWishlistSuccessHandler: this._moveToWishlistSuccessHandler,
            updateCartLinesQuantitySuccessHandler: this._updateCartlineQuantity,
        };

        const headerProps: IElicitSubscriptionHeaderProps = {
            cart: this.props.props.data.cart,
            className: 'minicart-modal-subscription-header',
            context: this.props.props.context,
            frequencyTable: this.props.props.config.frequencyTable!.map(freq => { return { freqId: freq.id, freqLabel: freq.label }; }),
            resources: {
                deliveryText: resources.subscriptionsMinicart_subscriptionDeliveryText,
                savingText: resources.subscriptionsMinicart_subscriptionSavingText,
                savingLabel: resources.subscriptionsMinicart_subscriptionSavingLabel,
                wineBottleSummaryCount: resources.subscriptionsMinicart_subscriptionWineBottleSummaryCount,
                boxNamePlaceholder: resources.subscriptionsMinicart__editBoxNamePlaceholder
            }
        };

        return (
            <div className='subscription-minicart-box-edit'>
                <input
                    className='subscription-minicart-box-input'
                    type='text'
                    placeholder={subscriptionsMinicart__editBoxNamePlaceholder}
                    value={this._name}
                    onChange={this._onBoxNameChange}
                />
                {this._renderFrequencies()}
                <div className='subscription-minicart-box-edit-group'>
                    <button className='subscription-minicart-box-edit-button' onClick={this._togglePopup} disabled={this._isCartEmpty}>{subscriptionsMinicart__editBoxBtn}</button>
                    {faqLink?.linkUrl?.destinationUrl &&
                        <a
                            className='subscription-minicart-box-edit-faq'
                            href={faqLink.linkUrl.destinationUrl}
                            target={faqLink.openInNewTab ? '_blank' : undefined}
                            aria-label={faqLink.ariaLabel}
                            role='button'
                            rel="noreferrer"
                        />
                    }
                </div>
                <Modal
                    autoFocus={true}
                    fade={true}
                    isOpen={this._popupState}
                    horizontalPosition={'center'}
                    verticalPosition={'center'}
                    zIndex={1000}
                    toggle={this._togglePopup}
                    applicationNode={'rsg-root'}
                    modalClassName={'subscription-minicart-edit-modal'}
                >
                    <ModalHeader toggle={this._togglePopup} />
                    <ModalBody>
                        <div className='subscription-minicart-header-container'>
                            <ElicitSubscriptionHeader {...headerProps} />
                        </div>
                        <ul className='msc-cart-line__container'>
                            {ElicitCartLineItems(elicitModalProps)?.map((cartLine, index) => {
                                return (
                                    <li className='msc-cart-line__item-wrapper' key={index}>
                                        {cartLine.cartline}
                                    </li>
                                );
                            })}
                        </ul>
                        <button onClick={this._emptyCart} className='subscription-minicart-edit-modal-button'>{subscriptionsMinicart_emptyCartBtn}</button>
                        {this.props.props.config.editBoxWarning && <RichTextComponent
                            className='subscription-minicart-edit-modal-warning'
                            text={this.props.props.config.editBoxWarning as string || ''}
                        />}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    private get _cartFilteredLines(): {
        subscriptionLines: CartLine[];
        lines: CartLine[];
    } {
        return filterCartLines(this.props.props.data.cart.result?.cart);
    }

    private _togglePopup(): void {
        if (this._popupState) {
            const { name: newName, frequency: newFreq } = getSubscriptionValues(this.props.props.data.cart.result?.cart);
            this._name = newName || '';
            this._selectedFrequencyId = newFreq || this._selectedFrequencyId;
        }
        this._popupState = !this._popupState;
    }

    private _renderFrequencies(): JSX.Element {
        return (
            <select className='subscription-minicart-select' value={this._selectedFrequencyId} onChange={this._onFrequencyChange}>
                {
                    this.props.props.config.
                        frequencyTable?.map(
                            (frequency, key) => (
                                <option
                                    value={frequency.id}
                                    key={key}
                                    aria-selected={frequency.id === this._selectedFrequencyId}
                                >
                                    {frequency.label}
                                </option>
                            )
                        )
                }
            </select>
        );
    }

    // ========================================================================
    // Taken from elicit-cart.tsx
    // ========================================================================

    private _removeItemFromCartHandler = (cartlineToRemove: CartLine) => {
        const cart = this.props.props.data.cart.result;

        if (cart) {
            this._generateRemovalAnalyticsEvent(cartlineToRemove);

            const input = {
                cartLineIds: [cartlineToRemove.LineId!.toString()]
            };

            cart.removeCartLines(input)
                .then(() => {
                    if (cart.cart.CartLines?.length === 0) { this._popupState = false; }
                })
                .catch((error) => {
                    this.props.props.telemetry.warning(error);
                    this.props.props.telemetry.debug('Unable to Remove Cart Line');
                });
        }
    };

    //----------------------------------------------------------
    // Almost identical to elicit-cart -- why isn't this shared code??
    //----------------------------------------------------------
    private _generateRemovalAnalyticsEvent = (cartlineToRemove: CartLine): void => {
        const productID = cartlineToRemove.ProductId;
        if (!productID) {
            return;
        }

        const product = this.props.props.data.products.result?.find(entry => entry.RecordId === productID);
        const attributes: AttributeValue[] = [];

        publish('removeFromCart', {
            product,
            quantity: cartlineToRemove.Quantity,
            attributes,
            subscription: true,
            context: this.props.props.context
        });
    };

    private _moveToWishlistSuccessHandler = (result: IWishlistActionSuccessResult, cartline?: CartLine) => {
        if (result.status === 'ADDED' && cartline) {
            this._removeItemFromCartHandler(cartline);
        }
    };

    private _updateCartlineQuantity = (cartlineToUpdate: CartLine, quantity: number) => {
        if (this.props.props.data.cart.result) {
            const input = {
                cartLineId: cartlineToUpdate.LineId!.toString(),
                newQuantity: quantity
            };
            this.props.props.data.cart.result.updateCartLineQuantity(input)
                .catch((error) => {
                    this.props.props.telemetry.warning(error);
                    this.props.props.telemetry.debug('Unable to update Cart Line quantity');
                });
        }
    };

    // ========================================================================
    // end
    // ========================================================================

    private get _isCartEmpty(): boolean {
        return this._cartFilteredLines.subscriptionLines?.length === 0;
    }

    private async _emptyCart(): Promise<void> {
        const { result: cart } = this.props.props.data.cart;

        if (cart) {
            const linesToRemove = this._cartFilteredLines.subscriptionLines;

            // Generate a removal event for each line
            linesToRemove.forEach(this._generateRemovalAnalyticsEvent);

            const lineIds = linesToRemove.map(line => line.LineId!);
            await cart.removeCartLines({ cartLineIds: lineIds });
            this._popupState = false;
        }
    }

    private _publishBoxNameChange(): void {
        this.props.onBoxNameChange(this._name);
    }

    private _onFrequencyChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        this._selectedFrequencyId = event.target.value;
        const frequency = this.props.props.config.frequencyTable?.find(freq => freq.id === this._selectedFrequencyId)!;
        this.props.onFrequencyChange(frequency);
    }

    private _onBoxNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this._name = event.target.value;
        this._publishBoxNameChange();
    }

    private async _getProductavailabilty(products: SimpleProduct[] | undefined): Promise<void> {
        if (products && products.length > 0) {
            const productIds: number[] = [];
            products.forEach((product) => {
                productIds.push(product.RecordId);
            });
            const availabilityAll = await getEstimatedAvailabilityAsync({ callerContext: this.props.props.context.actionContext }, {ProductIds: productIds, DefaultWarehouseOnly: true});
            this.props.props.data.productAvailabilites.result = availabilityAll.ProductWarehouseInventoryAvailabilities?.map((product) => {
                return {ProductId: product.ProductId, AvailableQuantity: product.PhysicalAvailable, ExtensionProperties: product.ExtensionProperties};
            });
        }
    }
}