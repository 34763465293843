import classnames from 'classnames';
import { reaction, toJS } from 'mobx';
import * as React from 'react';

import { getDimensionsForSelectedVariant, GetDimensionsForSelectedVariantInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { IModuleProps, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { CartLine, ProductAvailableQuantity, ProductDimensionValue, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { createProductAvailabilitiesForSelectedVariantInput, getProductAvailabilitiesForSelectedVariantAction, ProductAvailabilitiesForSelectedVariantInput } from '../../actions/get-product-availabilities-for-selected-variant.override.action';
import { publish } from '../../Utilities/analytics/analytics-dispatcher';
import CommerceAttributesParser from '../../Utilities/commerce-attributes-parser';
import { isProductSubscribable } from '../../Utilities/subscription-manager';

import {
    getBuyboxAddToCart,
    getBuyboxFindInStore,
    getBuyboxProductAddToWishlist,
    getBuyboxProductConfigure,
    getBuyboxProductDescription,
    getBuyboxProductPrice,
    getBuyboxProductQuantity,
    getBuyboxProductRating,
    getBuyboxProductTitle,
    IBuyboxAddToCartViewProps,
    IBuyboxAddToWishlistViewProps,
    IBuyboxFindInStoreViewProps,
    IBuyboxProductConfigureViewProps,
    IBuyboxProductQuantityViewProps
} from './components';
import { ISmweBuyboxData } from './smwe-buybox.data';
import { ISmweBuyboxProps, ISmweBuyboxResources } from './smwe-buybox.props.autogenerated';

export declare type IBuyboxErrorHost = 'ADDTOCART' | 'FINDINSTORE' | 'WISHLIST';

export interface IErrorState {
    errorHost?: IBuyboxErrorHost;

    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
}

export interface IBuyboxCallbacks {
    updateQuantity(newQuantity: number): void;
    updateErrorState(newErrorState: IErrorState): void;
    updateSelectedProduct(selectedProduct: Promise<SimpleProduct | null>): void;
    getDropdownName(dimensionType: number, resources: ISmweBuyboxResources): string;

    dimensionSelectedAsync(selectedDimensionId: number, selectedDimensionValueId: string): Promise<void>;
}

export interface IBuyboxState {
    quantity: number;

    errorState: IErrorState;

    selectedDimensions: { [id: number]: string | undefined };

    selectedProduct?: Promise<SimpleProduct | null>;
    productAvailability?: ProductAvailableQuantity;
}

export interface ISmweBuyboxViewProps extends ISmweBuyboxProps<ISmweBuyboxData> {
    state: IBuyboxState;
    ModuleProps: IModuleProps;
    ProductInfoContainerProps: INodeProps;
    MediaGalleryContainerProps: INodeProps;
    callbacks: IBuyboxCallbacks;
    mediaGallery?: React.ReactNode;
    productApellation?: React.ReactNode;
    acclaimRatings?: React.ReactNode;
    cookbookTagline?: React.ReactNode;
    content?: React.ReactNode[];
    productTitle?: React.ReactNode;
    productDescription?: React.ReactNode;
    productPrice?: React.ReactNode;
    productClubPrice?: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
    rating?: React.ReactNode;
    price?: React.ReactNode;
    addToWishlist?: IBuyboxAddToWishlistViewProps;
    show?: Boolean;
    addToCart: IBuyboxAddToCartViewProps;
    subscriptionButton?: IBuyboxAddToCartViewProps;
    findInStore?: IBuyboxFindInStoreViewProps;
    quantity?: IBuyboxProductQuantityViewProps;
    configure?: IBuyboxProductConfigureViewProps;
    eventSchedule?: React.ReactNode;
    tastingNotes?: React.ReactNode;
}

/**
 * Buybox Module
 */
class SmweBuybox extends React.Component<ISmweBuyboxProps<ISmweBuyboxData>, IBuyboxState> {

    private buyboxCallbacks: IBuyboxCallbacks = {
        updateQuantity: (newQuantity: number): void => {
            const errorState = { ...this.state.errorState };
            errorState.quantityError = undefined;

            this.setState({ quantity: newQuantity, errorState: errorState });
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.setState({ errorState: newErrorState });
        },
        updateSelectedProduct: (newSelectedProduct: Promise<SimpleProduct | null>): void => {
            this.setState({ selectedProduct: newSelectedProduct });
        },
        dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            return this._dimensionSelected(selectedDimensionId, selectedDimensionValueId);
        },
        getDropdownName: (dimensionType: number, resources: ISmweBuyboxResources): string => {
            return this._getDropdownName(dimensionType, resources);
        }
    };

    constructor(props: ISmweBuyboxProps<ISmweBuyboxData>, state: IBuyboxState) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            selectedProduct: undefined,
            selectedDimensions: {}
        };

        // This is fragile. It will only trigger is the cart isn't loaded initially.
        // If the cart is already loaded by the time this is constructed, this will never trigger and
        // the add to cart button will never appear!
        reaction(
            () => {
                return this.props.data.cart.result?.cart.CartLines;
            },
            async (cartLines) => {
                await this._updateProductAvailability(cartLines);
            },
            { delay: 500 }
        );
    }

    // Restoring quantity check on render
    // There's a chance this will conflict with the reaction version. Worst case, two calls will be initiated.
    // However, this module has worse issues. It has strayed too far from the core and won't support variants.
    public componentDidMount(): void {
        const { data } = this.props;

        if (data.cart.result?.cart) {
            this._updateProductAvailability(data.cart.result.cart.CartLines).catch(() => {
                console.error('Failed to fetch availability');
            });
        }

        // Analytics event emitter
        // tslint:disable-next-line: no-floating-promises
        Promise.all([data.product.result, data.productSpecificationData.result, data.categories])
            .then(() => {
                publish('productDetailView', {
                    product: data.product.result,
                    attributes: CommerceAttributesParser.getParsedAttributes(data.productSpecificationData.result || []),
                    category: this._getCurrentCategory(),
                    context: this.props.context,
                });
            }).catch(e => this.props.telemetry.exception(e));
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public render(): JSX.Element | null {
        const {
            slots: {
                mediaGallery,
                productApellation,
                content,
                productTitle,
                productPrice,
                productClubPrice,
                acclaimRatings,
                cookbookTagline,
                productDescription,
                eventSchedule
            },
            data: {
                product: { result: product }
            },
            config: { className = '' }
        } = this.props;

        if (!product) {
            return null;
        }
        const attributeList = this.props.data && this.props.data.productSpecificationData && this.props.data.productSpecificationData.result;

        const canAddToCartAttribute = attributeList && attributeList.filter(
            attribute => attribute.Name! === 'Can add to cart'
        );

        const productTypeAttribute = attributeList && attributeList.find(
            attribute => attribute.Name === 'Product Type'
        );

        const productType = (productTypeAttribute?.TextValue)?.replace(/\s+/g, '-').toLowerCase();
        const show = (canAddToCartAttribute && canAddToCartAttribute.length) ? canAddToCartAttribute[0].BooleanValue : true;
        const shouldNavigateToCart = this.props.config.shouldNavigateToCart;
        const viewProps: ISmweBuyboxViewProps = {
            ...(this.props as ISmweBuyboxProps<ISmweBuyboxData>),
            state: this.state,
            mediaGallery: mediaGallery && mediaGallery.length > 0 ? mediaGallery[0] : undefined,
            productApellation: productApellation && productApellation.length > 0 ? productApellation[0] : undefined,
            acclaimRatings: acclaimRatings && acclaimRatings.length > 0 ? acclaimRatings[0] : undefined,
            cookbookTagline: cookbookTagline && cookbookTagline.length > 0 ? cookbookTagline[0] : undefined,
            content: content && content.length > 0 ? content : undefined,
            productTitle: productTitle && productTitle.length > 0 ? productTitle[0] : undefined,
            productDescription: productDescription && productDescription.length > 0 ? productDescription[0] : undefined,
            eventSchedule: eventSchedule && eventSchedule.length > 0 ? eventSchedule[0] : undefined,
            productPrice: productPrice && productPrice.length > 0 ? productPrice[0] : undefined,
            productClubPrice: productClubPrice && productClubPrice.length > 0 ? productClubPrice[0] : undefined,
            tastingNotes: mediaGallery && mediaGallery.length > 1 ? mediaGallery[1] : undefined,
            show,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: classnames('ms-buybox__content', productType)
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            callbacks: this.buyboxCallbacks,
            title: getBuyboxProductTitle(this.props),
            description: getBuyboxProductDescription(this.props),
            configure: getBuyboxProductConfigure(this.props, this.state, this.buyboxCallbacks),
            findInStore: getBuyboxFindInStore(this.props, this.state, this.buyboxCallbacks),
            price: getBuyboxProductPrice(this.props),
            addToCart: getBuyboxAddToCart(this.props, this.state, this.buyboxCallbacks, shouldNavigateToCart, productTypeAttribute, attributeList || []),
            subscriptionButton: (this._showSubscriptionButton) ? getBuyboxAddToCart(this.props, this.state, this.buyboxCallbacks, shouldNavigateToCart, productTypeAttribute, attributeList || [], true) : undefined,
            addToWishlist: getBuyboxProductAddToWishlist(this.props, this.state, this.buyboxCallbacks),
            rating: getBuyboxProductRating(this.props),
            quantity: getBuyboxProductQuantity(this.props, this.state, this.buyboxCallbacks),
        };

        return this.props.renderView(viewProps);
    }

    private get _showSubscriptionButton(): boolean {
        const availableQuantity = (this.state.productAvailability && this.state.productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity - this.props.context.app.config.outOfStockThreshold, 0);
        if (!stockLeft) { return false; }

        const attributes = this.props.data.productSpecificationData.result;
        return isProductSubscribable(attributes);
    }

    private _dimensionSelected = async (selectedDimensionId: number, selectedDimensionValue?: string): Promise<void> => {
        const {
            data: {
                product: { result: product },
                productDimensions: { result: productDimensions },
            },
            context: {
                actionContext,
                request: {
                    apiSettings: {
                        channelId
                    }
                }
            }
        } = this.props;

        const
            {
                selectedDimensions
            } = this.state;

        if (!product || !productDimensions) {
            return;
        }

        // Step 1: Update state to indicate which dimensions are selected
        const newSelectedDimensions: { [id: number]: string | undefined } = { ...selectedDimensions };
        newSelectedDimensions[selectedDimensionId] = selectedDimensionValue;

        this.setState({ selectedDimensions: newSelectedDimensions });

        // Step 2: Clear any errors indicating the dimension wasn't selected
        if (this.state.errorState.configureErrors[selectedDimensionId]) {
            const errorState = { ...this.state.errorState };
            errorState.configureErrors[selectedDimensionId] = undefined;

            this.setState({ errorState: errorState });
        }

        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const mappedDimensions = productDimensions.map(dimension => {
            return {
                DimensionTypeValue: dimension.DimensionTypeValue,
                DimensionValue: this._updateDimensionValue(dimension, newSelectedDimensions[dimension.DimensionTypeValue]) || dimension.DimensionValue,
                ExtensionProperties: dimension.ExtensionProperties
            };
        }).filter(dimension => {
            return dimension && dimension.DimensionValue;
        });

        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProduct = new Promise<SimpleProduct | null>(async (resolve, reject) => {
            const newProduct = (await getSelectedVariant(
                new SelectedVariantInput(
                    product.MasterProductId ? product.MasterProductId : product.RecordId,
                    channelId,
                    mappedDimensions
                ),
                actionContext
            ));

            if (newProduct) {
                await getDimensionsForSelectedVariant(
                    new GetDimensionsForSelectedVariantInput(
                        newProduct.MasterProductId ? newProduct.MasterProductId : newProduct.RecordId,
                        channelId,
                        mappedDimensions
                    ),
                    actionContext
                );
            }

            resolve(newProduct);
        });

        this.setState({ selectedProduct: selectedProduct });

        await selectedProduct;
    };

    private _updateDimensionValue = (productDimensionFull: ProductDimensionFull, newValueId: string | undefined): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find(dimension => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    private _getDropdownName = (dimensionType: number, resources: ISmweBuyboxResources): string => {
        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };

    private async _updateProductAvailability(cartLines: CartLine[] | undefined): Promise<void> {
        let qtyInCart = 0;
        if (cartLines) {
            cartLines.map(cartline => {
                if (cartline.ProductId === this.props.data.product.result?.RecordId) {
                    qtyInCart = qtyInCart + cartline.Quantity!;
                }
            });
        }
        const productAvailability = await getProductAvailabilitiesForSelectedVariantAction(
            createProductAvailabilitiesForSelectedVariantInput(this.props.context.actionContext) as ProductAvailabilitiesForSelectedVariantInput,
            this.props.context.actionContext
        );

        if (productAvailability) {
            const clonedProductAvailability = {
                AvailableQuantity: productAvailability.AvailableQuantity! - qtyInCart,
                ExtensionProperties: productAvailability.ExtensionProperties,
                ProductId: productAvailability.ProductId,
                UnitOfMeasure: productAvailability.UnitOfMeasure
            } as ProductAvailableQuantity;
            this.setState({ productAvailability: clonedProductAvailability });
        }
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private _getCurrentCategory(): string {
        const categorySlug = this._getCategorySlug();

        // Attempt to locate category slug in the category hierarchy
        // Array.concat doesn't work with mobx Observables, so convert to JS first
        let categories = toJS(this.props.data.categories.result || []);
        while (categories.length) {
            // Pull an entry from the front or back of the list -- either is fine (the front may be marginally faster)
            const category = categories.shift();

            // Check for a match
            if (category!.Slug === categorySlug) {
                return category!.Name || categorySlug;
            }

            // No match, so add any children to the list to scan
            if (category!.Children && category!.Children.length) {
                categories = categories.concat(category!.Children); // Add new entries to the front or back of the list -- again it doesn't matter which
            }
        }

        // No match was found, so use the category slug as a fallback
        return categorySlug;
    }

    //----------------------------------------------------------
    // Figure out the slug for the current category
    //----------------------------------------------------------
    private _getCategorySlug(): string {

        // Based on the page type (category or product) we want to remove a
        // different number of chunks of the URL
        const sliceIndexes = {
            '.c': -1,
            '.p': -2,
        };

        const currentUrl = window.location.pathname;

        // Use the last 2 chars of the pathname to determine if we're on a product (.p) or category (.c) page
        const suffix = currentUrl.slice(-2);

        if (sliceIndexes[suffix]) {
            // Slice off part of the current URL to find the category URL
            return currentUrl.split('/').slice(0, sliceIndexes[suffix]).join('/');
        }

        // We don't really know what to do here. Just use the current URL, even though it will probably fail.
        return currentUrl;
    }

}

export default SmweBuybox;
