// TODO: Need to find replacement for GetActiveCartInput retail actions in 2.0
// import { getActiveCart, GetActiveCartInput } from '@msdyn365-commerce-modules/retail-actions';

// TODO: may serve as replacement, need to test functionality
import { BaseCartState, getCartState, GlobalStateInput, ICartState } from '@msdyn365-commerce/global-state';

import { Modal, ModalBody, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { ICoreContext } from '@msdyn365-commerce/core';
import * as React from 'react';
import Button from 'reactstrap/lib/Button';

export interface IProductLightboxProps {
    id: string;
    width: number;
    height: number;
    productLightboxHref: string;
    iframeSrc?: string;
    buttonText?: string;
    lightboxClassName?: string;
    iframeClassName?: string;
    context: ICoreContext;
}

export interface IProductAttributeProps {
    TextValue: string;
    Name: string;
}

interface IProductLightboxData {
    isOpen: boolean;
}

/**
 * Lightbox Component
 */
export default class ProductLightbox extends React.PureComponent<IProductLightboxProps, IProductLightboxData> {
   public innerRef: React.RefObject<HTMLDivElement>;
    constructor(props: IProductLightboxProps) {
        super(props);
        this.state = { isOpen: false };
        this.innerRef=React.createRef();
        this._handleKeyDown=this._handleKeyDown.bind(this);
    }

    public componentDidMount(): undefined {
        window.addEventListener && window.addEventListener('message', this.onMessage);
        return;
    }

   private _handleKeyDown = (e: React.KeyboardEvent<HTMLElement>):void  => {

        //Fetch node list from which required elements could be grabbed as needed.
        const focusable = document.getElementById('modal_parent');
        const elements = focusable?.querySelectorAll('button, [href], input, select, textarea, li, [tabindex]:not([tabindex="-1"]), a') as NodeListOf<HTMLDivElement>;
        const firstFocusable = elements[0];
        const lastFocusable = elements[elements.length - 1];

        if (e.ctrlKey || e.altKey) {
            return;
        }
        firstFocusable.focus();

        const keys = {
            9: () => { //9 = TAB
                if (e.shiftKey && e.target === firstFocusable) {
                    lastFocusable.focus();
                }

                if (e.target === lastFocusable) {
                    firstFocusable.focus();
                }
            }
        };

        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    };

    public render(): JSX.Element {
        const { lightboxClassName, iframeClassName } = this.props;
        // @ts-ignore
        const iframeSrcObj = (new URL(this.props.iframeSrc));
        const iframeId = `iframe-${this.props.id}`;
        return (
            <div className={lightboxClassName && lightboxClassName !== ''? lightboxClassName : 'product-lightbox'}>
                {this._launchLightboxButton(this.props.buttonText)}
                {(this.state.isOpen) &&
                    <Modal
                        fade={true}
                        isOpen={this.state.isOpen}
                        horizontalPosition={'center'}
                        verticalPosition={'center'}
                        zIndex={1000}
                        toggle={this._closeModal}
                        applicationNode={'rsg-root'}
                        aria-labelledby={iframeId}
                        onLoad={()=>{this.innerRef.current?.focus();}}
                        id='modal_parent'
                    >
                        {/* eslint-disable react/no-unknown-property */}
                        <div ref={this.innerRef} onLoad={(e: React.KeyboardEvent<HTMLDivElement>)=>{this._handleKeyDown(e);}}>
                            <ModalHeader toggle={this._closeModal}/>
                            <ModalBody>
                            <div className={iframeClassName && iframeClassName !== '' ? iframeClassName : 'product-iframe'}>
                                {/* tslint:disable-next-line: react-iframe-missing-sandbox */}
                                <iframe
                                    className={iframeClassName && iframeClassName !== '' ? `${iframeClassName}-content` : 'iframe-module-content'}
                                    src={iframeSrcObj.href}
                                    width={this.props.width}
                                    height={this.props.height}
                                    title={this.props.buttonText}
                                    id={iframeId}
                                />
                            </div>
                            </ModalBody>
                        </div>
                        <a href='#' className='sr-only' aria-hidden='true'></a>
                    </Modal>}

            </div>
        );
    }

    private onMessage = async (e: MessageEvent) => {
        if (!e.isTrusted || e.origin !== window.origin) {
            return;
        }
        // @ts-ignore
        const iframeSrcObj = (new URL(this.props.iframeSrc));

        // TODO: Need to find replacement for GetActiveCartInput retail actions in 2.0
        // if (e.data.url === iframeSrcObj.href) {
        //     setTimeout(async () => {
        //         const context = this.props.context;
        //         const cartInput = new GetActiveCartInput(context.request.apiSettings, true);
        //         const newCart = await getActiveCart(cartInput, context.actionContext);
        //         context.actionContext.update(cartInput, newCart);
        //     },         10);
        // }

        // TODO: may work as replacement, need to test
        if (e.data.url === iframeSrcObj.href) {
            setTimeout(async () => {
                const context = this.props.context;
                const cartInput = new GlobalStateInput<ICartState>('CARTSTATE', BaseCartState, context.actionContext.requestContext.apiSettings);
                const newCart = await getCartState(context.actionContext);
                context.actionContext.update(cartInput, newCart);
                await newCart.refreshCart({});
            },         10);
        }
    };

    private _launchLightboxButton(buttonText: string | undefined): JSX.Element | null {
          return (<Button id={this.props.id} className='launch-lightbox-button' onClick={this._openModal} aria-label="Click here to view more details about this product">{buttonText ? buttonText : 'Quick View'}</Button>);
    }

    private _divTabIndexChange=()=>{
        const divElement=document.getElementsByTagName('div');
        for(var i=0; i<divElement.length; i++){
            if(divElement[i].style.display==='block'){
                divElement[i].setAttribute('tabindex','0');
            }
        }
    };

    private _closeModal = () => {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }));
      const quickView: HTMLElement | null = document.getElementById(this.props.id);
      quickView!.focus();
    };
    private _openModal = () => {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }), ()=>{this._divTabIndexChange();});
    };
}