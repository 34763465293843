import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { ISearchResultModalViewProps, Title } from '../../../modules/smwe-search-result-container/components';
import { IRefineMenuViewProps, ISearchResultContainerViewProps, ISortByViewProps, ITitleViewProps } from '../../../modules/smwe-search-result-container/smwe-search-result-container';
import { ISmweSearchResultContainerProps } from '../../../modules/smwe-search-result-container/smwe-search-result-container.props.autogenerated';

// tslint:disable-next-line:cyclomatic-complexity
const SearchResultContainerView: React.FC<ISearchResultContainerViewProps & ISmweSearchResultContainerProps<{}>> = props => {
    const { SearchResultContainer, products, pagination, ProductsContainer, ProductSectionContainer, choiceSummary, isMobile, modalToggle, searchResultModal, TitleViewProps,
        refineMenu, sortByOptions, CategoryNavContainer, RefineAndProductSectionContainer, errorMessage, categoryDescription } = props;
    // customization for 1.0
    const { showRefiners, showSortBy, showResultCount, categoryNamePrefix } = props.config;
    if (isMobile) {
        return (
            <Module {...SearchResultContainer}>
                {/*TODO remove after final testing */}
                {/*categoryHierarchy && renderCategoryHierarchy(categoryHierarchy)*/}
                {renderTitle(TitleViewProps, showResultCount!, categoryNamePrefix!)}
                {showRefiners && !props.config.pulldownRefiners && choiceSummary}
                {showRefiners && !props.config.pulldownRefiners && modalToggle}
                {showRefiners && !props.config.pulldownRefiners && createSearchResultModal(searchResultModal, refineMenu, sortByOptions, showSortBy!)}
                {showRefiners && props.config.pulldownRefiners && refineMenu && renderRefinerPulldown(refineMenu)}
                {categoryDescription}
                <Node {...ProductsContainer}>
                    {errorMessage}
                    {products}
                </Node>
                {pagination}
            </Module>
        );
    }
    return (
        <Module {...SearchResultContainer}>
            {showResultCount && TitleViewProps && <Node {...CategoryNavContainer}>
                {renderTitleCount(TitleViewProps)}
            </Node>}
            <Node {...RefineAndProductSectionContainer}>
                {showRefiners && !props.config.pulldownRefiners && refineMenu && renderRefiner(refineMenu)}
                {showRefiners && props.config.pulldownRefiners && refineMenu && renderRefinerPulldown(refineMenu)}
                <Node {...ProductSectionContainer}>
                    {TitleViewProps && renderTitle(TitleViewProps, showResultCount!, categoryNamePrefix!)}
                    {showRefiners && choiceSummary}
                    {showSortBy && sortByOptions && renderSort(sortByOptions, showSortBy)}
                    {categoryDescription}
                    <Node {...ProductsContainer}>
                        {errorMessage}
                        {products}
                    </Node>
                    {pagination}
                </Node>
            </Node>
        </Module>
    );

};

const createSearchResultModal = (modalProps: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps, showSortBy: boolean): JSX.Element => {
    return React.cloneElement(modalProps.modal, {}, modalProps.modalHeader, createModalBody(modalProps, refineMenu, sortByDropDown, showSortBy), modalProps.modalFooter);
};

const createModalBody = (props: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps, showSortBy: boolean): JSX.Element | null => {
    if (sortByDropDown) {
        return React.cloneElement(props.modalBody, {}, renderSort(sortByDropDown, showSortBy), renderRefiner(refineMenu));
    }
    return null;
};

const renderRefiner = (props: IRefineMenuViewProps): JSX.Element | null => {
    const { refiners, RefineMenuContainer, RefinerSectionContainer } = props;
    if (refiners) {
        return (
            <Node {...RefinerSectionContainer}>
                <Node {...RefineMenuContainer}>
                    {refiners.map((submenu, index) => (
                        <React.Fragment key={index}>
                            {submenu}
                        </React.Fragment>
                    ))}
                </Node>
            </Node>
        );
    }
    return null;
};

const renderRefinerPulldown = (props: IRefineMenuViewProps): JSX.Element | null => {
    const { refiners, RefineMenuContainer, RefinerSectionContainer, pulldownText, activeRefiners } = props;
    const activeRefinersText = getActiveRefinersText(activeRefiners);
    const dynamicPulldownText = getPulldownText(activeRefinersText, pulldownText);
    if (refiners) {
        return (
            <Node {...RefinerSectionContainer}>
                {/* tslint:disable-next-line:jsx-no-lambda react-this-binding-issue */}
                <button onClick={() => togglePulldown(getActiveRefinersText(activeRefiners), pulldownText)} className='ms-search-result-container__refiner-pulldown-button' id='refiner-pulldown-button'>
                    <span className='ms-search-result-container__refiner-pulldown-button-content' id='refiner-pulldown-button-content'>{dynamicPulldownText}</span>
                </button>
                <div className='ms-search-result-container__refiner-pulldown-container pulldown-hide' id='refiner-pulldown-container'>
                    <Node {...RefineMenuContainer}>
                        {refiners.map((submenu, index) => (
                            <React.Fragment key={index}>
                                {submenu}
                            </React.Fragment>
                        ))}
                    </Node>
                    {/* tslint:disable-next-line:jsx-no-lambda react-this-binding-issue */}
                    <button onClick={() => togglePulldown(getActiveRefinersText(activeRefiners), pulldownText)} className='ms-search-result-container__refiner-close-pulldown-button'>Close</button>
                </div>
            </Node>
        );
    }
    return null;
};

const getPulldownText = (activeRefiners: string, buttonText: string | undefined): string => {
    const buttonElement = document.getElementById('refiner-pulldown-button');
    const spanElement = document.getElementById('refiner-pulldown-button-content');
    if (buttonElement && spanElement) {
        if (buttonElement.className === 'ms-search-result-container__refiner-pulldown-button refiner-pulldown-button-expanded' || !activeRefiners) {
            return spanElement.innerText = buttonText || '';
        } else {
            return spanElement.innerText = activeRefiners;
        }
    }
    return '';
};

const togglePulldown = (activeRefiners: string, buttonText: string | undefined): void => {
    const buttonElement = document.getElementById('refiner-pulldown-button');
    if (buttonElement) {
        buttonElement.classList.toggle('refiner-pulldown-button-expanded');
        getPulldownText(activeRefiners, buttonText);
    }
    const pulldownElement = document.getElementById('refiner-pulldown-container');
    if (pulldownElement) {
        pulldownElement.classList.toggle('pulldown-hide');
    }

};

const getActiveRefinersText = (activeRefiners: ProductRefinerValue[]): string => {
    let formatedActiveRefiners = '';
    activeRefiners.forEach(refiner => {
        if (refiner.UnitText === 'USD') {
            formatedActiveRefiners += `$${refiner.LeftValueBoundString}-$${refiner.RightValueBoundString} / `;
        } else if (refiner.LeftValueBoundString) {
            formatedActiveRefiners += `${refiner.LeftValueBoundString} / `;
        }
    });
    // removes trailing slash and whitespace
    formatedActiveRefiners = formatedActiveRefiners.substring(0, formatedActiveRefiners.length - 2);
    return formatedActiveRefiners;
};

const renderSort = (props: ISortByViewProps, showSortBy: boolean): JSX.Element | null => {
    const { SortingContainer, sortByDropDown } = props;
    if (sortByDropDown && showSortBy) {
        return (
            <Node {...SortingContainer}>
                {sortByDropDown}
            </Node>
        );
    }
    return null;
};

const renderTitle = (props: ITitleViewProps, showResultPrefix: boolean, categoryNamePrefix: string): JSX.Element | null => {
    const { title, TitleContainer } = props;
    const titlePrefix = <Title className='ms-search-result__collection-title-prefix' text={categoryNamePrefix} />;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h1 className='h2'>
                    {showResultPrefix && titlePrefix}
                    {title.titleText}
                </h1>
            </Node>
        );
    }
    return null;
};

const renderTitleCount = (props: ITitleViewProps): JSX.Element | null => {
    const { title, TitleContainer } = props;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h5>
                    {title.titleCount}
                </h5>
            </Node>
        );
    }
    return null;
};

export default SearchResultContainerView;