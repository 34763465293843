import * as React from 'react';

import { Alert, format, Heading, INodeProps, Quantity } from '@msdyn365-commerce-modules/utilities';
import {
    AddToWishlistComponent,
    IWishlistActionErrorResult,
    PriceComponent,
    RatingComponent
} from '@msdyn365-commerce/components';
import { getUrlSync } from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { IBuyboxCallbacks, IBuyboxState, ISmweBuyboxData, ISmweBuyboxProps, ISmweBuyboxResources } from '../';
import AddToCart, { IAddToCartFailureResult } from '../../../components/addtocart.component';
import { ElicitAmountSelector } from '../../../components/elicit-amountselector.component';
import { getConfigureErrors, getGenericError, getQuantityError } from '../utilities/error-utilities';

export function getBuyboxProductTitle(props: ISmweBuyboxProps<ISmweBuyboxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product } },
        config: { titleHeadingTag = 'h1' }
    } = props;

    return product && (
        <Heading
            className='ms-buybox__product-title'
            headingTag={titleHeadingTag}
            text={product.Name || ''}
        />
    );
}

export function getBuyboxProductDescription(props: ISmweBuyboxProps<ISmweBuyboxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product } }
    } = props;

    return product && (
        <p className='ms-buybox__product-description'>{product.Description}</p>
    );
}

export interface IBuyboxAddToCartViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxAddToCart(
    props: ISmweBuyboxProps<ISmweBuyboxData>,
    state: IBuyboxState,
    callbacks: IBuyboxCallbacks,
    shouldNavigateToCart: boolean,
    productTypeAttribute: AttributeValue | undefined,
    attributes: AttributeValue[],
    displaySubscription: boolean = false
): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        data: { product: { result: product } },
        resources
    } = props;

    const
        {
            quantity,
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct
        } = state;

    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined,
            configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
            otherError: getGenericError(result, resources, context)
        });
    };

    const emailDeliveryModeCode: string | undefined = props.data && props.data.channelConfiguration.result && props.data.channelConfiguration.result.EmailDeliveryModeCode;
    const keyedInPrice = props.data.keyedInPrice.result;
    let cartText: string = resources.addToCartText;
    if (props.config.addToCartText) {
        cartText = props.config.addToCartText;
    }
    if (displaySubscription) {
        cartText = resources.smweBuybox__subscriptionBtnText;
    }
    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-cart-container'
        },
        button: product && (state.productAvailability || product.ItemId?.toLowerCase() === 'giftcard') && (
            <AddToCart
                useElicitAddToCart={!!props.config.useForElicit}
                isSubscriptionItem={displaySubscription}
                addToCartText={cartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{ product: product, emailDeliveryModeCode: emailDeliveryModeCode, keyedInPrice: keyedInPrice }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                getSelectedProduct={selectedProduct}
                shouldNavigateToCart={shouldNavigateToCart}
                productAvailability={state.productAvailability}
                productTypeAttribute={productTypeAttribute}
                productAttributes={attributes}
            />
        ),
        errorBlock: !displaySubscription && (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'ADDTOCART'}
            />
        )
    };
}

export function getBuyboxProductPrice(props: ISmweBuyboxProps<ISmweBuyboxData>): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        data: { productPrice: { result: productPrice } },
        resources
    } = props;

    return productPrice && (
        <PriceComponent
            id={id}
            typeName={typeName}
            context={context}
            data={{ price: productPrice }}
            freePriceText={resources.priceFree}
            originalPriceText={resources.originalPriceText}
            currentPriceText={resources.currentPriceText}
        />
    );
}

export function getBuyboxProductRating(props: ISmweBuyboxProps<ISmweBuyboxData>): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        data: { ratingsSummary: { result: ratingsSummary } },
        resources
    } = props;

    const ratingComponent = ratingsSummary && ratingsSummary.averageRating && (
        <RatingComponent
            avgRating={ratingsSummary.averageRating || 0}
            readOnly={true}
            ariaLabel={format(resources.averageRatingAriaLabel, ratingsSummary.averageRating, '5')}
            ratingCount={`${ratingsSummary.reviewsCount}`}
            data={{}}
            context={context}
            id={id}
            typeName={typeName}
        />
    ) || undefined;

    return ratingsSummary && ratingComponent && (
        ratingComponent
    );
}

export interface IBuyboxAddToWishlistViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxProductAddToWishlist(props: ISmweBuyboxProps<ISmweBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToWishlistViewProps | undefined {
    const {
        id,
        typeName,
        context,
        data: {
            product: { result: product },
            wishlists: { result: wishlists }
        },
        resources
    } = props;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct
        } = state;

    const onAddToWishlistFailed = (result: IWishlistActionErrorResult) => {
        callbacks.updateErrorState({
            errorHost: 'WISHLIST',
            configureErrors: result.status === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
        });
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-wishlist-container'
        },
        button: product && (
            <AddToWishlistComponent
                addToWishlistButtonText={resources.addToWishlistButtonText}
                removeFromWishlistButtonText={resources.removeFromWishlistButtonText}
                addToWishlistMessage={resources.addToWishlistMessage}
                removedFromWishlistMessage={resources.removedFromWishlistMessage}
                addItemToWishlistError={resources.addItemToWishlistError}
                removeItemFromWishlistError={resources.removeItemFromWishlistError}
                nameOfWishlist={resources.nameOfWishlist}
                data={{ product: product, wishlists: wishlists }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToWishlistFailed}
                getSelectedProduct={selectedProduct}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'WISHLIST'}
            />
        )
    };
}

export interface IBuyboxProductQuantityViewProps {
    ContainerProps: INodeProps;
    LabelContainerProps: INodeProps;

    heading: React.ReactNode;
    errors?: React.ReactNode;

    input: React.ReactNode;
}
export function getBuyboxProductQuantity(props: ISmweBuyboxProps<ISmweBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxProductQuantityViewProps {
    const {
        resources,
        context: {
            app: {
                config: {
                    maxQuantityForCartLineItem
                }
            }
        }
    } = props;

    const
        {
            quantity,
            errorState: {
                quantityError,
            }
        } = state;

    const onChange = (newValue: number) => {
        if (callbacks.updateQuantity) {
            callbacks.updateQuantity(newValue);
        }
    };

    const _updateQuantity = (event:React.ChangeEvent<HTMLSelectElement>): void => {
        if (callbacks.updateQuantity) {
            callbacks.updateQuantity(parseInt(event.target.value, 10));
        }
    };

    const showQuanatityAsDropdown: boolean = props.config.showQuantityAsDropdown || false;
    const availability = state.productAvailability?.AvailableQuantity && state.productAvailability.AvailableQuantity - props.context.app.config.outOfStockThreshold;
    const productAvailability = availability || state.quantity || maxQuantityForCartLineItem || 10;

    return {
        ContainerProps: {
            className: 'ms-buybox__quantity'
        },
        LabelContainerProps: {
            tag: 'label',
            className: 'ms-buybox__product-quantity-label',
            htmlFor: 'ms-buybox__product-quantity-input'
        },
        heading: (
            <div className='ms-buybox__product-quantity-label-heading'>{resources.productQuantityHeading}</div>
        ),
        errors: quantityError && (
            <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                <span className='msi-exclamation-triangle' aria-hidden='true' />
                <span>{quantityError}</span>
            </span>
        ),
        input: (showQuanatityAsDropdown)? (
            _generateSelectMenu(productAvailability, quantity, _updateQuantity)
        ) : (!props.config.useForElicit) ? (
            <Quantity
                id='ms-buybox__product-quantity-input'
                max={maxQuantityForCartLineItem || 10}
                currentCount={quantity}
                onChange={onChange}
                inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
            />) : (
                <ElicitAmountSelector
                    _updateQuantityRaw={onChange}
                    currentQuantity={quantity}
                />
            )
    };
}

const _generateMenu = (quantity: number) => {
    const nodes = [];

    for (let i = 1; i <= quantity; i++) {
        // tslint:disable-next-line:react-a11y-role-has-required-aria-props
        nodes.push(<option className='ms-buybox__quantity__select-menu__item' value={i}>{i}</option>);
    }

    return nodes;
};

const _generateSelectMenu = (maxQuantity: number, currentQuantity: number, onchange:(event:React.ChangeEvent<HTMLSelectElement>) => void): JSX.Element => {
    return (
        <select className='ms-buybox__quantity__select-menu' value={currentQuantity} onChange={onchange}>
            {
                _generateMenu(maxQuantity)
            }
        </select>
    );
};

export interface IBuyboxErrorBlockProps {
    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
    resources: ISmweBuyboxResources;
    showError: boolean;
}
export const BuyboxErrorBlock: React.FC<IBuyboxErrorBlockProps> = ({ showError, configureErrors, quantityError, otherError, resources }) => {
    let errorMessages: (string | undefined)[] = [];

    errorMessages = Object.values(configureErrors).filter(message => message !== undefined);

    if (quantityError) {
        errorMessages.push(quantityError);
    }

    if (otherError) {
        errorMessages.push(otherError);
    }

    const isMixedCart = errorMessages.find(item => {
        return item === resources.mixedCartErrorText;
    });

    return (
        <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={resources.buyboxErrorMessageHeader} >
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                {!isMixedCart && (<span>{resources.buyboxErrorMessageHeader}</span>)}
            </div>
            {errorMessages.map((message, index: number) => {
                if (message) {
                    return (
                        <div key={index} className='msc-alert__line'>{message}</div>
                    );
                } else {
                    return null;
                }
            })}
        </Alert>
    );
};