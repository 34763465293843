import * as React from 'react';

import { Button, format } from '@msdyn365-commerce-modules/utilities';
import { PriceComponent } from '@msdyn365-commerce/components';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { Coupon } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export interface IElicitPromoCodeProps extends IComponentProps<{}> {
    cart: ICartState | undefined;
    promoCodeHeadingText: string;
    appliedPromoCodeHeadingText: string;
    appliedPromoCodeSubheadingText: string;
    removePromoAriaLabelFormat: string;
    promoPlaceholderText: string;
    promoCodeApplyButtonText: string;
    collapseTimeOut: number;
    removePromoText: string;
    invalidPromoCodeErrorText: string;
    failedToAddPromoCodeErrorText: string;
    duplicatePromoCodeErrorText: string;
    failedToRemovePromoCodeErrorText: string;
    promoCodeApplyCallback?(): void;
}

export interface IElicitPromoCodeComponent extends IComponent<IElicitPromoCodeProps> { }

const PromoCodeComponentActions = {
};

interface IElicitPromoCodeState {
    isCollapseOpen: boolean;
    promoCodeInputValue: string;
    error: string;
    canApply: boolean;
}

/**
 *
 * The PromoCode component renders the promocode section.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class ElicitPromoCode extends React.PureComponent<IElicitPromoCodeProps, IElicitPromoCodeState> {

    constructor(props: IElicitPromoCodeProps, state: IElicitPromoCodeState) {
        super(props);
        this.state = {
            isCollapseOpen: false,
            promoCodeInputValue: '',
            error: '',
            canApply: false
        };
    }

    public render(): JSX.Element {
        return (
            <>
                <div className='msc-promo-code__heading'>{this.props.promoCodeHeadingText}</div>
                {this._renderForm(this.props.promoPlaceholderText, this.props.promoCodeApplyButtonText, this.props.cart)}
                <p className={this.state.error ? 'msc-promo-code__error msc-alert-danger' : ''} aria-live='assertive'>{this.state.error}</p>
                {this._renderAppliedPromoCode(this.props)}
            </>
        );
    }

    private _onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const error = e.target.value === '' ? '' : this.state.error;
        this.setState({
            promoCodeInputValue: e.target.value, error: error,
            canApply: e.target.value ? true : false
        });
    };

    private _applyPromotion = (cartState: ICartState | undefined) => {
        if (!cartState || !cartState.cart) {
            return;
        }
        const appliedPromo = this.state.promoCodeInputValue;

        cartState.addPromoCode({ promoCode: appliedPromo })
            .then(result => {
                if (result.status === 'SUCCESS') {
                    // show success text
                    this.setState({ promoCodeInputValue: '', error: '', canApply: false});
                } else if (result.substatus === 'ALREADYADDED') {
                    this.setState({ error: this.props.duplicatePromoCodeErrorText });
                } else {
                    this.setState({ error: this.props.invalidPromoCodeErrorText });
                }
            })
            .catch(error => {
                this.setState({ error: this.props.failedToAddPromoCodeErrorText });
            });
    };

    private _renderForm = (promoPlaceholderText: string, promoCodeApplyButtonText: string, cartState: ICartState | undefined) => {
        return (
            // tslint:disable-next-line:react-this-binding-issue jsx-no-lambda
            <form onSubmit={e => {e.preventDefault(); this._applyPromotion(cartState); }} className='msc-promo-code__form'>
                <input
                    className='msc-promo-code__input'
                    aria-label={promoPlaceholderText}
                    onChange={this._onInputChange}
                    value={this.state.promoCodeInputValue}
                    placeholder={promoPlaceholderText}
                />
                <Button
                    title={promoCodeApplyButtonText}
                    className='msc-promo-code__btn-apply'
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => { this._applyPromotion(cartState); }}
                    disabled={!this.state.canApply}
                >
                    {promoCodeApplyButtonText}
                </Button>
            </form>
        );
    };

    private _removePromotion = (cartState: ICartState | undefined, event: React.MouseEvent) => {
        if (!cartState) {
            return;
        }
        const code = event.currentTarget.getAttribute('data-value') || '';
        cartState.removePromoCodes({
            promoCodes: [
                code
            ]
        })
            .then(result => {
                if (result.status === 'SUCCESS') {
                    this.setState({ error: ''});
                }
            })
            .catch(() => {
                this.setState({ error: this.props.failedToRemovePromoCodeErrorText});
            });

    };
    private _calculateDiscount = (code: string, cartState: ICartState | undefined) => {
        if (!cartState || !cartState.cart || !cartState.cart.CartLines || cartState.cart.CartLines.length === 0 || !code) {
            return;
        }
        let discountAmount = 0;
        for (const line of cartState.cart.CartLines) {
            if (line.DiscountLines) {
                for (const discountLine of line.DiscountLines) {
                    if (discountLine.DiscountCode === code) {
                        discountAmount += discountLine.DiscountCost!;
                    }
                }
            }
        }
        return discountAmount * -1;
    };

    private _renderAppliedPromoCode = (props: IElicitPromoCodeProps) => {
        if (!props.cart || !props.cart.cart || !props.cart.cart.Coupons || !(props.cart.cart.Coupons.length > 0)) {
            return;
        }
        return (
            <div className='msc-promo-code__applied'>
                <div className='msc-promo-code__applied-heading'>{this.props.appliedPromoCodeHeadingText}</div>
                <div className='msc-promo-code__applied-subheading'>{this.props.appliedPromoCodeSubheadingText}</div>
                <div className='msc-promo-code__applied-lines'>
                    {
                        props.cart.cart.Coupons.map((coupon: Coupon) => {
                            const ariaLabel = props.removePromoAriaLabelFormat ? format(props.removePromoAriaLabelFormat, props.removePromoText, coupon.Code) : '';
                            return (
                                <div key={coupon.Code} className='msc-promo-code__applied-line'>
                                    <div className='msc-promo-code__applied-line-value'>
                                        <span className='msc-promo-code__applied-line-value-code'>{coupon.Code}</span>
                                        <span className='msc-promo-code__applied-line-value-price'>
                                            {this._calculateDiscount(coupon.Code || '', props.cart) === 0
                                                ? null
                                                : <>
                                                    (
                                                        <PriceComponent
                                                            data={{
                                                                price: {
                                                                    CustomerContextualPrice: this._calculateDiscount(coupon.Code || '', props.cart)
                                                                }
                                                            }}
                                                            context={props.context}
                                                            id={props.id}
                                                            typeName={props.typeName}
                                                            className={'msc-promo-code__applied-line-discount-value'}
                                                        />
                                                    )
                                                </>
                                            }
                                        </span>
                                    </div>
                                    <Button
                                        title={props.removePromoText}
                                        className={'msc-promo-code__applied-line-btn-remove'}
                                        // tslint:disable-next-line:jsx-no-lambda react-this-binding-issue
                                        onClick={(event) => {this._removePromotion(props.cart, event);}}
                                        data-value={coupon.Code}
                                        aria-label={ariaLabel}
                                    >
                                        {props.removePromoText}
                                    </Button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };
}

// @ts-ignore
export const ElicitPromoCodeComponent: React.FunctionComponent<IElicitPromoCodeProps> = msdyn365Commerce.createComponent<IElicitPromoCodeComponent>(
    'ElicitPromoCode',
    { component: ElicitPromoCode, ...PromoCodeComponentActions }
);