import classNames from 'classnames';
import * as React from 'react';
import { UncontrolledTooltip, mapToCssModules, PaginationItem, PaginationLink, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';

/**
 * UncontrolledPagination properties.
 */
export interface IUncontrolledPaginationProps extends React.HTMLAttributes<HTMLElement> {
    /** The current url */
    url: string;

    /** The qsp to use with the pagination */
    qsp: string;

    /** Number of items */
    items?: number;

    /** Items per page */
    itemsPerPage?: number;

    /** Index of first item returned */
    startingItem?: number;

    /** The text to display for the previous arrow */
    previousText?: React.ReactChild;

    /** The text to display for the next arrow */
    nextText?: React.ReactChild;

    /** The aria-label for the previous arrow */
    previousAriaLabel?: string;

    /** The aria-label for the next arrow */
    nextAriaLabel?: string;

    /** Tag property to set if you want the HTML tag to be something else */
    tag?: React.ReactType;

    /** List tag property to set if you want the HTML tag to be something else */
    listTag?: React.ReactType;

    /** ClassName Property to set any CSS classnames on the pagination */
    className?: string;

    /** List ClassName Property to set any CSS classnames on the pagination */
    listClassName?: string;

    /** CssModule Property to set any CSS classModule on the pagination */
    cssModule?: object;

    /** Set the pagination bar showed size */
    size?: string;

    /** Define a string that labels the current element */
    'aria-label'?: string;

    /** Define a string that provide the role on the pagination */
    role?: string;

    /** The Id for the next arrow */
    nextId?: string;

    /** The Id for the prev arrow */
    prevId?: string;

    /** The text for the next arrow aria described */
    nextAreaDescribed?: string;

    /** The text for the prev arrow aria described */
    prevAreaDescribed?: string;

    /** The telemetry content */
    telemetryContent?: ITelemetryContent;
}

/**
 * Uncontrolled Pagination component
 */
export default class SMWEPagination extends React.PureComponent<IUncontrolledPaginationProps> {
    public static defaultProps: Partial<IUncontrolledPaginationProps> = {
        tag: 'nav',
        listTag: 'ul',
        'aria-label': 'pagination',
        items: 0,
        itemsPerPage: 25,
        startingItem: 0
    };

    private static readonly pagesDisplayed: number = 7;

    private maxPages: number;

    private activePage: number = 0;

    private qsps: { [param: string]: string };

    private url: string;

    private hash: string;

    constructor(props: IUncontrolledPaginationProps) {
        super(props);
        this.maxPages = this.props.items ? Math.ceil(this.props.items / this.props.itemsPerPage!) : 0;
        this.activePage = Math.floor((this.props.startingItem || 0) / this.props.itemsPerPage!);
        this.qsps = {};
        const splitUrl = this.props.url.split('?');

        if (splitUrl[1]) {
            this.url = splitUrl[0];
            const secondSplit = splitUrl[1].split('#');
            this.hash = secondSplit[1] ? `#${secondSplit[1]}` : '';
            const parsedQsps = secondSplit[0].split('&');

            for (const qsp of parsedQsps) {
                const qspPair = qsp.split('=');

                if (this.props.qsp !== qspPair[0]) {
                    this.qsps[qspPair[0]] = qspPair[1];
                }
            }
        } else {
            const secondSplit = splitUrl[0].split('#');
            this.url = secondSplit[0];
            this.hash = secondSplit[1] ? `#${secondSplit[1]}` : '';
        }
    }

    public render(): JSX.Element {
        const {
            className,
            baseUrl,
            qsp,
            items,
            itemsPerPage,
            startingItem,
            previousText,
            nextText,
            previousAriaLabel,
            nextAriaLabel,
            listClassName,
            cssModule,
            children,
            size,
            tag: Tag,
            listTag: ListTag,
            'aria-label': label,
            role,
            ...props

        }: any = this.props;

        this.maxPages = this.props.items ? Math.ceil(this.props.items / this.props.itemsPerPage!) : 0;
        this.activePage = Math.floor((this.props.startingItem || 0) / this.props.itemsPerPage!);
        this.qsps = {};
        const splitUrl = this.props.url.split('?');

        if (splitUrl[1]) {
            this.url = splitUrl[0];
            const secondSplit = splitUrl[1].split('#');
            this.hash = secondSplit[1] ? `#${secondSplit[1]}` : '';
            const parsedQsps = secondSplit[0].split('&');

            for (const activeQsp of parsedQsps) {
                const qspPair = activeQsp.split('=');

                if (this.props.qsp !== qspPair[0]) {
                    this.qsps[qspPair[0]] = qspPair[1];
                }
            }
        } else {
            const secondSplit = splitUrl[0].split('#');
            this.url = secondSplit[0];
            this.hash = secondSplit[1] ? `#${secondSplit[1]}` : '';
        }

        const paginationClasses = mapToCssModules(
            classNames(
                className
            ),
            cssModule
        );

        const listpaginationClasses = mapToCssModules(
            classNames(
                listClassName,
                'msc-pagination',
                {
                    [`msc-pagination-${size}`]: !!size
                }
            ),
            cssModule
        );

        return (
            <Tag className={paginationClasses} role='navigation' aria-label={label}>
                <ListTag {...props} className={listpaginationClasses}>
                    {this._generatePageLinks()}
                </ListTag>
            </Tag>
        );
    }

    private _generateUrl(page: number): string {
        const items = this.props.itemsPerPage! * page;
        const keys = Object.keys(this.qsps);
        let qspUrl = page > 0 ? `?${this.props.qsp}=${items}` : '';

        keys.forEach((key: string) => {
            qspUrl = qspUrl ? `${qspUrl}&${key}=${this.qsps[key]}` : `?${key}=${this.qsps[key]}`;
        });

        return this.url + qspUrl + this.hash;
    }

    private _generatePaginationArrow(next: boolean, disable: boolean, className: string): React.ReactElement<PaginationItem> {
        const url = disable ? undefined : this._generateUrl(this.activePage + (next ? 1 : -1));
        const tooltipId = next ? this.props.nextId : this.props.prevId;
        const ariaDescribedBy = next ? this.props.nextAreaDescribed : this.props.prevAreaDescribed;
        const placement = next ? 'right' : 'left';
        const tag = disable ? 'span' : 'a';

        return (
            <PaginationItem disabled={disable} className={className}>
                <PaginationLink
                    id={tooltipId}
                    href={url}
                    next={next}
                    previous={!next}
                    aria-describedby={ariaDescribedBy}
                    tag={tag}
                    aria-label={disable ? undefined : next ? this.props.nextAriaLabel : this.props.previousAriaLabel}
                    aria-disabled={disable}
                    telemetryContent={this.props.telemetryContent}
                >
                    {next ? this.props.nextText : this.props.previousText}
                </PaginationLink>
                {tooltipId && (
                    <UncontrolledTooltip placement={placement} id={ariaDescribedBy} target={tooltipId}>
                        {next ? 'next' : 'previous'}
                    </UncontrolledTooltip>
                )}
            </PaginationItem>
        );
    }

    private _generatePaginationItem(page: number): React.ReactElement<PaginationItem> {
        const active = this.activePage === page;
        const url = this._generateUrl(page);
        page += 1;
        const label = `Page ${page}`;
        return (
            <PaginationItem active={active} >
                <PaginationLink href={active ? undefined : url} {...(active ? { 'aria-current': 'page' } : {})} aria-label={label} telemetryContent={this.props.telemetryContent}>
                    {page}
                </PaginationLink>
            </PaginationItem>
        );
    }

    private _generateEllipse(): React.ReactElement<PaginationItem> {
        return (
            <PaginationItem>
                <PaginationLink tag='span'>
                    ...
                </PaginationLink>
            </PaginationItem>
        );
    }

    private _generatePageLinks(): React.ReactFragment {
        const displayCountSide = 3;
        let leftDistance = this.activePage;
        let rightDistance = this.maxPages - this.activePage - 1;
        const leftEllipse = this.maxPages > SMWEPagination.pagesDisplayed && leftDistance > 3;
        const rightEllipse = this.maxPages > SMWEPagination.pagesDisplayed && rightDistance > 3;

        leftDistance = Math.min(leftDistance, displayCountSide);
        rightDistance = Math.min(rightDistance, displayCountSide);
        const pages = [];
        const ellipseOffset = leftEllipse ? -1 : 0;
        const displayedPagesOnLeft = leftDistance + (displayCountSide - rightDistance) + ellipseOffset;
        const startingIndex = Math.max(this.activePage - displayedPagesOnLeft, 0);
        const endIndex = Math.min((startingIndex + 5 + (rightEllipse ? 0 : 1) + (leftEllipse ? 0 : 1)),
            (rightEllipse ? this.maxPages - 1 : this.maxPages));

        if (leftEllipse) {
            pages.push(this._generatePaginationItem(0));
            pages.push(this._generateEllipse());
        }

        for (let i = startingIndex; i < endIndex; i++) {
            pages.push(this._generatePaginationItem(i));
        }

        if (rightEllipse) {
            pages.push(this._generateEllipse());
            pages.push(this._generatePaginationItem(this.maxPages - 1));
        }

        return (
            <>
                {this._generatePaginationArrow(false, (this.activePage === 0), 'previous')}
                {pages}
                {this._generatePaginationArrow(true, (this.activePage === this.maxPages - 1), 'next')}
            </>
        );
    }
}
