import {
    IBuyboxAddToCartViewProps,
    IBuyboxProductQuantityViewProps
} from '@msdyn365-commerce-modules/buybox/dist/types';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { AsyncResult } from '@msdyn365-commerce/core';
import * as React from 'react';
import Table from 'reactstrap/lib/Table';
import { ISmweBuyboxViewProps } from '../../../modules/smwe-buybox/smwe-buybox';

const SmweBuyboxView: React.FC<ISmweBuyboxViewProps> = props => {
    const {
        ModuleProps,
        MediaGalleryContainerProps,
        ProductInfoContainerProps,
        addToCart,
        quantity,
        title,
        productDescription,
        productTitle,
        productPrice,
        productClubPrice,
        show,
        acclaimRatings,
        cookbookTagline,
        eventSchedule,
        subscriptionButton
    } = props;
    // @ts-ignore
    const tableClass: AsyncResult<string> = props.context.actionContext.get('String', 'Generic-SkuSelectorClasses');
    return (
        <Module {...ModuleProps}>
            <Node {...MediaGalleryContainerProps}>{props.mediaGallery}</Node>
            <Node {...ProductInfoContainerProps}>
                {props.productApellation && (
                    <div>{props.productApellation}</div>
                )}
                {title}
                {productDescription}
                {eventSchedule}
                {acclaimRatings}
                {cookbookTagline}
                {show && <div className={`sku-selector-container row ${tableClass && tableClass.result}`}>
                    <Table borderless responsive size='lg'>
                        <thead>
                            <tr>
                                {productTitle && <th scope='col' className='sku-selector-product-title'>Product</th>}
                                {productPrice && <th scope='col' className='sku-selector-product-price'>Retail</th>}
                                {productClubPrice && <th scope='col' className='sku-selector-product-price'>Your Price</th>}
                                {quantity && <th scope='col' className='sku-selector-product-quantity'>QTY</th>}
                                {addToCart && <th scope='col' className='sku-selector-add-to-cart' />}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {productTitle && <td className='sku-selector-product-title'>{productTitle}</td>}
                                {productPrice && <td className='sku-selector-product-price'>{productPrice}</td>}
                                {productClubPrice && <td className='sku-selector-product-price'>{productClubPrice}</td>}
                                {quantity && <td className='sku-selector-product-quantity'>{_renderQuantity(quantity)}</td>}
                                {addToCart && <td className='sku-selector-add-to-cart'>{_renderAddToCart(addToCart)}</td>}
                                {subscriptionButton && <td className='sku-selector-add-to-cart'>{_renderAddToCart(subscriptionButton)}</td>}
                            </tr>
                        </tbody>
                    </Table>
                </div>}
                {props.content &&
                    props.content.map(
                        (contentSlotData: React.ReactNode, index: number) => {
                         return (
                                <React.Fragment key={index}>
                                    {contentSlotData}
                                </React.Fragment>
                            );
                        }
                    )}

            </Node>
        </Module>
    );
};

const _renderAddToCart = (addToCart: IBuyboxAddToCartViewProps): JSX.Element => {
    const { ContainerProps, errorBlock, button } = addToCart;

    return (
        <Node {...ContainerProps}>
            {errorBlock}
            {button}
        </Node>
    );
};

const _renderQuantity = (quantity: IBuyboxProductQuantityViewProps): JSX.Element => {
    const { ContainerProps, LabelContainerProps, input, errors } = quantity;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>
                {errors}
            </Node>
            {input}
        </Node>
    );
};

export default SmweBuyboxView;
