import * as React from 'react';

import { Heading, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { getUrlSync } from '@msdyn365-commerce/core';
import { ProductDimension } from '@msdyn365-commerce/retail-proxy';

import { IBuyboxCallbacks, IBuyboxState } from '../smwe-buybox';
import { ISmweBuyboxData } from '../smwe-buybox.data';
import { ISmweBuyboxProps } from '../smwe-buybox.props.autogenerated';
import { getConfigureErrors } from '../utilities/error-utilities';
import { BuyboxErrorBlock } from './index';

export interface IFindInStoreFailureResult {
    missingDimensions?: ProductDimension[];
}

export interface IBuyboxFindInStoreViewProps {
    storeSelector?: React.ReactNode;
    heading?: React.ReactNode;
    description?: React.ReactNode;
    errors?: React.ReactNode;
    button?: React.ReactNode;

    ContainerProps: INodeProps;

    openFindInStoreDialog(): Promise<void>;
}

export function getBuyboxFindInStore(props: ISmweBuyboxProps<ISmweBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxFindInStoreViewProps | undefined {
    const {
        data: {
            product: { result: product },
            storeSelectorStateManager: { result: storeSelectorStateManager },
            cart: { result: cart },
            deliveryOptions: { result: deliveryOptions }
        },
        slots: {
            storeSelector
        },
        resources,
        context: {
            request: {
                channel: {
                    PickupDeliveryModeCode
                } = { PickupDeliveryModeCode: undefined }
            }
        }
    } = props;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            }
        } = state;

    const onClick = () => findInStoreOnClick(props, state, callbacks);

    if (!product || !storeSelectorStateManager || storeSelector.length === 0) {
        return undefined;
    }

    // If no delivery options present on the product, or none of the delivery options
    // match the PickupDeliveryModeCode, that means the item cannot be used for BOPIS
    if (!deliveryOptions ||
        !deliveryOptions.DeliveryOptions ||
        !PickupDeliveryModeCode ||
        !deliveryOptions.DeliveryOptions.find(option => option.Code === PickupDeliveryModeCode)) {
        return undefined;
    }

    return {
        ContainerProps: {
            className: 'ms-buybox__find-in-store'
        },

        storeSelector: storeSelector[0],

        openFindInStoreDialog: onClick,

        heading: (
            <Heading
                className='ms-buybox__find-in-store-heading'
                headingTag='h4'
                text={resources.findInStoreHeaderText}
            />
        ),

        description: (
            <p className='ms-buybox__find-in-store-description'>{resources.findInStoreDescriptionText}</p>
        ),

        errors: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'FINDINSTORE'}
            />
        ),

        button: (
            <button
                className='ms-buybox__find-in-store-button'
                onClick={onClick}
                color='secondary'
                aria-label={resources.findInStoreLinkText}
                disabled={cart === undefined}
            >
                {resources.findInStoreLinkText}
            </button>
        )
    };
}

async function findInStoreOnClick(props: ISmweBuyboxProps<ISmweBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): Promise<void> {
    const {
        data: {
            storeSelectorStateManager: { result: storeSelectorStateManager },
            cart: { result: cart }
        },
        resources,
        context
    } = props;

    const {
        selectedProduct
    } = state;

    let dataProduct = props.data.product.result;

    if (selectedProduct) {
        dataProduct = (await selectedProduct) || dataProduct;
    }

    if (!dataProduct || !storeSelectorStateManager) {
        return;
    }

    const product = dataProduct;

    const missingDimensions = product.Dimensions && product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

    if (missingDimensions && missingDimensions.length > 0) {
        if (callbacks.updateErrorState) {
            callbacks.updateErrorState(
                {
                    errorHost: 'FINDINSTORE',
                    configureErrors: getConfigureErrors(missingDimensions, resources),
                }
            );
        }
    } else {
        storeSelectorStateManager.openDialog({
            product,
            onLocationSelected: orgUnitLocation => {
                if (!cart) {
                    return Promise.resolve();
                }

                return cart.addProductToCart({ product: product, count: 1, location: orgUnitLocation })
                    .then(result => {
                        const navigationUrl = getUrlSync('cart', context.actionContext);

                        if (result.status === 'SUCCESS' && navigationUrl) {
                            window.location.assign(navigationUrl);
                        }
                    }).catch(error => {
                        return;
                    });
            }
        }).catch(error => {
            return;
        });
    }

    return;
}