import {
    IBuyboxAddToCartViewProps,
    IBuyboxProductQuantityViewProps
} from '@msdyn365-commerce-modules/buybox/dist/types';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { AsyncResult } from '@msdyn365-commerce/core';
import * as React from 'react';
import { ISmweBuyboxViewProps } from '../../../modules/smwe-buybox/smwe-buybox';
import { ISmweBuyboxProps } from '../definition-extensions/smwe-buybox.ext.props.autogenerated';

const SmweBuyboxView: React.FC<ISmweBuyboxViewProps & ISmweBuyboxProps<{}>> = props => {
    const { subscriptionButton, ModuleProps, MediaGalleryContainerProps, ProductInfoContainerProps, addToCart, quantity, title, productDescription, productPrice, show, acclaimRatings, cookbookTagline, eventSchedule } = props;
    // @ts-ignore
    const tableClass: AsyncResult<string> = props.context.actionContext.get('String', 'Generic-SkuSelectorClasses');
    return (
        <Module {...ModuleProps}>
            <div className='ms-buybox__mobile-header'>
                {props.productApellation && <>{props.productApellation}</>}
                {title}
            </div>
            <div className='ms-buybox__media-gallery-wrapper'>
                <Node {...MediaGalleryContainerProps}>
                    {props.mediaGallery}
                </Node>
                {props.tastingNotes}
            </div>
            <Node {...ProductInfoContainerProps}>
                {props.productApellation && <>{props.productApellation}</>}
                {title}
                {productDescription}
                {eventSchedule}
                {acclaimRatings}
                {cookbookTagline}
                {show && <div className={`sku-selector ${tableClass && tableClass.result}`}>
                    {productPrice && <div className='sku-selector__product-price'>{productPrice}</div>}
                    {quantity && <div className='sku-selector__product-quantity'>{_renderQuantity(quantity)}</div>}
                    {addToCart && <div className='sku-selector__add-to-cart'>{_renderAddToCart(addToCart)}</div>}
                    {subscriptionButton && <div className='sku-selector__add-to-subscription'>{_renderAddToCart(subscriptionButton)}</div>}
                </div>}
                {props.content &&
                    props.content.map(
                        (contentSlotData: React.ReactNode, index: number) => {
                         return (
                                <React.Fragment key={index}>
                                    {contentSlotData}
                                </React.Fragment>
                            );
                        }
                    )
                }
            </Node>
            {props.slots?.productCollection?.length &&
                <div className='ms-buybox__product-collection'>
                    {props.slots.productCollection.map(
                            (productCollectionSlotData: React.ReactNode, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        {productCollectionSlotData}
                                    </React.Fragment>
                                );
                            }
                        )
                    }
                </div>
            }
        </Module>
    );
};

const _renderAddToCart = (addToCart: IBuyboxAddToCartViewProps): JSX.Element => {
    const { ContainerProps, errorBlock, button } = addToCart;

    return (
        <Node {...ContainerProps}>
            {errorBlock}
            {button}
        </Node>
    );
};

const _renderQuantity = (quantity: IBuyboxProductQuantityViewProps): JSX.Element => {
    const { ContainerProps, LabelContainerProps, input, errors } = quantity;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>
                {errors}
            </Node>
            {input}
        </Node>
    );
};

export default SmweBuyboxView;
