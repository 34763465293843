import { CategoryHierarchy, IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';

// Refiner Remappers, stored in global config
export interface IRemapper {
    originalName: string;
    newName: string;
}

//-----------------------------------------------------
// Remove category refiners that aren't in the current
// position within the hierarchy.
//-----------------------------------------------------
export function filterInvalidCategories(refiners: IProductRefinerHierarchy[], categoryHierarchy: CategoryHierarchy[], remappers: IRemapper[]): void {
    // If we don't have any refiners (yet), don't do anything
    if (!refiners || !refiners.length) {
        return undefined;
    }

    // Find the category refiner name
    const categoryRemapper = remappers && remappers.find(remapRecord => remapRecord.originalName.toLowerCase() === `category`);
    const categoryRefinerName = categoryRemapper ? categoryRemapper.newName : `Category`;

    // Find the category refiners
    const categoryRefiners = refiners.find(entry => (entry.KeyName === categoryRefinerName));

    // Filter out invalid entries
    if (categoryRefiners) {
        // Construct a list of valid categories (note: this only goes one level deep!)
        const categoryIDs = {};

        if (categoryHierarchy && categoryHierarchy[0]) {
            categoryHierarchy[0].Children?.forEach(entry => categoryIDs[entry.RecordId] = true);
        }

        // Remove refiners that aren't in the list of categories
        categoryRefiners.Values = categoryRefiners.Values.filter(entry => (entry.RefinerRecordId && categoryIDs[entry.RefinerRecordId]));
    }
}
