//==============================================================================
//==============================================================================
import { get, groupBy, uniq } from 'lodash';

import { IActionContext } from '@msdyn365-commerce/core';
import { searchAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { CartLine, ProductProperty, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { IContext } from '@msdyn365-commerce/retail-proxy/dist/interfaces';

//==============================================================================
//==============================================================================
export interface IAttributeList {
    [attributeName: string]: string;
}

export interface IAttributeBank {
    [productId: number]: IAttributeList;
}

//==============================================================================
//==============================================================================
let attributeCache: IAttributeBank = {};

//----------------------------------------------------------
// Given a set of product IDs, return product attributes for
// all of them.
//
// Returns an IAttributeBank, grouped by product ID then
// attribute name.
//----------------------------------------------------------
async function fetchProductAttributes(productIDs: number[], context: IActionContext): Promise<IAttributeBank> {

    // Fetch product information from retail server
    const ctx: IContext = { callerContext: context };
    const productSearchCriteria: ProductSearchCriteria = {
        Ids: productIDs,
        Context: {
            ChannelId: context.requestContext.apiSettings.channelId,
            CatalogId: context.requestContext.apiSettings.catalogId
        },
    };

    const result = await searchAsync(ctx, productSearchCriteria);

    // Transform results into a useful format
    return result.reduce(
        (output, product) => {
            const translatedProperties: ProductProperty[] = get(product, 'ProductProperties[0].TranslatedProperties', []);

            // Convert from an array of records to a hash of values
            output[product.RecordId] = translatedProperties.reduce(
                (attributes, property) => {
                    if (property.FriendlyName && property.ValueString) {
                        attributes[property.FriendlyName] = property.ValueString;
                    }

                    return attributes;
                },
                {} as IAttributeList
            );

            return output;
        },
        {} as IAttributeBank
    );
}

//----------------------------------------------------------
// Given a set of product IDs, return product attributes for
// all of them.
//
// Uses a local cache at the product ID level to prevent
// duplicate requests.
//
// Returns an IAttributeBank, grouped by product ID then
// attribute name.
//----------------------------------------------------------
export async function getAttributesByProductID(productIDs: number[], context: IActionContext): Promise<IAttributeBank> {

    // Split IDs into cached and uncached groups
    const groupedIDs = groupBy(productIDs, id => (attributeCache[id] ? 'cached' : 'uncached'));

    // Fetch products not already in the cache
    const uncachedAttributes = groupedIDs.uncached ? await fetchProductAttributes(groupedIDs.uncached, context) : {};

    // Merge new products into the cache
    attributeCache = {...attributeCache, ...uncachedAttributes};

    // Add cached entries to the result
    const result = uncachedAttributes;  // This does nothing except change the name. Referring to the combined result as "uncached" is unclear.
    groupedIDs.cached && groupedIDs.cached.forEach(id => {
        result[id] = attributeCache[id];
    });

    return result;
}

//----------------------------------------------------------
// Given an array of cart lines, return product attributes for
// all products in the cart
//----------------------------------------------------------
export function getAttributesForCart(cartLines: CartLine[], context: IActionContext): Promise<IAttributeBank> {

    // Create a list of product IDs from the cart lines, removing any duplicates
    const productIDs = uniq(cartLines.map(line => line.ProductId!));

    // Fetch the attributes for the requested products
    return getAttributesByProductID(productIDs, context);
}