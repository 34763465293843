import { format } from '@msdyn365-commerce-modules/utilities';
import {
    ICoreContext
} from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { AsyncResult, CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import debounce from 'lodash/debounce';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SubscriptionCommerceValues } from '../../../common/subscription-commerce.values';
import { CartUtilities } from '../../../Utilities/cart-utils';
import { calculateSavingPercentAmount } from '../../../Utilities/elicit-savings-calculation';
import { filterCartLines, getSubscriptionValues, updateSubscription } from '../../../Utilities/subscription-manager';
import { IEditSubscriptionLinkData, IFrequencyTableData } from '../elicit-cart.props.autogenerated';

export interface IElicitSubscriptionHeaderProps {
    // elicitCartProps: IElicitCartProps<IElicitCartData>;
    className: string;
    cart: AsyncResult<ICartState>;
    frequencyTable: IFrequencyTableData[];
    editSubscriptionLink?: IEditSubscriptionLinkData;
    resources: {
        deliveryText: string;
        savingText: string;
        savingLabel: string;
        wineBottleSummaryCount: string;
        boxNamePlaceholder: string;
    };
    context: ICoreContext;
    useFetchFromCart?: boolean;
}

/**
 * Subscription header renders the part of the UI that allows the user to change their subscription
 */
@observer
export class ElicitSubscriptionHeader extends React.Component<IElicitSubscriptionHeaderProps> {
    /**
     * stores the user's selected frequency, gets defaulted in constructor
     */
    @observable private _selectedFrequency: IFrequencyTableData | undefined;

    @observable private _name: string = SubscriptionCommerceValues.SUBSCRIPTION_DEFAULT_NAME;

    constructor(props: IElicitSubscriptionHeaderProps) {
        super(props);

        this._onFrequencyChange = this._onFrequencyChange.bind(this);
        this._onNameChange = this._onNameChange.bind(this);
        this._onNameBlur = this._onNameBlur.bind(this);

        // debounce function now has typings and requires an intermediate function to use properly.
        // implement later. new typings declare DebouncedFunc<() => Promise<void>> not () => Promise<void>
        this._publishNameChange = debounce(this._publishNameChange.bind(this), 500) as unknown as () => Promise<void>;

        reaction(
            () => [this.props.cart.result?.cart],
            () => {
                const values = getSubscriptionValues(this.props.cart.result?.cart);
                this._name = values.name || '';
                this._selectedFrequency = this.props.frequencyTable.find(freq => freq.freqId === values.frequency) || this._selectedFrequency;
            }
        );
    }

    public render(): JSX.Element {
        if (!this.props.cart.result) { return <div />; }
        const {
            className,
            resources: {
                wineBottleSummaryCount,
                deliveryText,
                savingText,
                savingLabel,
                boxNamePlaceholder
            },
            cart: { result: cartState },
            context
        } = this.props;
        const subState = getSubscriptionValues(cartState.cart);
        const subLines = filterCartLines(cartState.cart).subscriptionLines;
        const formatter = context.cultureFormatter.formatCurrency;
        const total = CartUtilities.countCartLineNetPrice(subLines);
        const discountedTotal = CartUtilities.countCartLineDiscountedPrice(subLines);
        const totalBottles = CartUtilities.countCartLineAmount(subLines);

        //only show savings if subscription box qualifies for subsequent orders
        const futureSavings = calculateSavingPercentAmount(subLines);
        let savings = total - discountedTotal;
        if (!isFinite(savings)) {
            savings = 0;
        }
        const savingFormatted = formatter(savings);
        return (
            <div className={`subscription-header ${className || ''}`}>
                <div className='subscription-header__box'>
                    <div className='subscription-header__box-icon' />
                    <div className='subscription-header__box-summary'>
                        <input
                            className='subscription-header__box-name'
                            type='text'
                            placeholder={boxNamePlaceholder}
                            value={this._name}
                            onChange={this._onNameChange}
                            onBlur={this._onNameBlur}
                        />
                        <div className='subscription-header__box-items'>{format(wineBottleSummaryCount, totalBottles)}</div>
                    </div>
                </div>
                <div className='subscription-header__frequency'>
                    <div className='subscription-header__frequency-label'>{deliveryText}</div>
                    {this._frequencyTable(subState.frequency)}
                </div>
                <div className='subscription-header__discount'>
                    <div className='subscription-header__discount-label'>{savingLabel}</div>
                    <div className='subscription-header__discount-wrapper'>
                        <div className='subscription-header__discount-total'>{formatter(futureSavings ? discountedTotal : total)}</div>
                        {savings && futureSavings ? <div className='subscription-header__discount-savings'>{format(savingText, savingFormatted)}</div> : ''}
                    </div>
                </div>
            </div>
        );
    }

    private _frequencyTable(initialId?: string): JSX.Element {
        const { frequencyTable } = this.props;

        let firstId: string | undefined;
        if (!initialId && this._selectedFrequency) {
            firstId = frequencyTable?.find(freq => !!freq)?.freqId;
        }
        return (
            <select className='subscription-header__frequency-select' value={this._selectedFrequency?.freqId || initialId || firstId} onChange={this._onFrequencyChange} >
                {
                    frequencyTable?.map(
                        frequency => (
                            <option
                                key={frequency.freqId}
                                value={frequency.freqId}
                                aria-selected={
                                    (frequency.freqId === this._selectedFrequency?.freqId) ||
                                    (initialId === frequency.freqId) ||
                                    (firstId === frequency.freqId)
                                }
                            >
                                {frequency.freqLabel}
                            </option>
                        )
                    )
                }
            </select>
        );
    }

    private async _pushUpdateToCart(updates: { key: string; value: string }[]): Promise<void> {
        const cartChanges: CommerceProperty[] = updates.map(change => {
            return {
                Key: change.key,
                Value: {
                    StringValue: change.value
                }
            };
        });

        await updateSubscription(this.props.cart, cartChanges, this.props.context);
    }

    private async _publishNameChange(): Promise<void> {
        await this._pushUpdateToCart([
            {
                key: SubscriptionCommerceValues.SUBSCRIPTION_NAME,
                value: this._name
            }
        ]);
    }

    private async _onNameChange(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        this._name = event.target.value;
    }

    private async _onNameBlur(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        return this._publishNameChange();
    }

    private async _onFrequencyChange(event: React.ChangeEvent<HTMLSelectElement>): Promise<void> {
        this._selectedFrequency = this.props.frequencyTable?.find(frequncy => frequncy.freqId === event.target.value);
        return this._submitSubscriptionToCart();
    }

    private async _submitSubscriptionToCart(): Promise<void> {

        const { context, cart: cartState } = this.props;
        const cartChanges: CommerceProperty[] = [];

        cartChanges.push({
            Key: SubscriptionCommerceValues.SUBSCRIPTION_FREQUENCY,
            Value: {
                StringValue: this._selectedFrequency!.freqId
            }
        });

        await updateSubscription(cartState, cartChanges, context);
        return;
    }
}