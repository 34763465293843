import { ICartState } from '@msdyn365-commerce/global-state';
import { AsyncResult } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { CartUtilities } from '../../../Utilities/cart-utils';
import { calculateSavingPercentAmount } from '../../../Utilities/elicit-savings-calculation';
import { filterCartLines } from '../../../Utilities/subscription-manager';
import { IEditSubscriptionLinkData } from '../elicit-cart.props.autogenerated';

export interface IElicitSubscriptionUpsell {
    editSubscriptionLink?: IEditSubscriptionLinkData;
    cart: AsyncResult<ICartState>;
}

/**
 * displays an upsell for getting more subscription items to the user
 */
export class ElicitSubscriptionUpsell extends React.Component<IElicitSubscriptionUpsell> {

    public render(): JSX.Element {
        const {
            cart: { result: cartState },
        } = this.props;
        if (!cartState) { return <div />; }
        const subLines = filterCartLines(cartState.cart).subscriptionLines;
        const total = CartUtilities.countCartLineNetPrice(subLines);
        const savingRatioAmount = calculateSavingPercentAmount(subLines);
        let savingRatio = total / savingRatioAmount;
        if (!isFinite(savingRatio)) {
            savingRatio = 0;
        }
        const totalBottles = CartUtilities.countCartLineAmount(subLines);
        const showUpsell = totalBottles < 12 ? true : false;
        const upsellPercent = savingRatioAmount < 4 ? 10 : 15;
        const upsellBottleCount = upsellPercent === 10 ? 4 - totalBottles : 12 - totalBottles;
        return (
            <div className='subscription-upsell'>
                {showUpsell &&
                    <div className='subscription-upsell__text'>
                        Your order includes a subscription for a
                        <span className='subscription-upsell__text-current-bottles'>{` ${totalBottles} bottle `}</span>
                        box. Add
                        <span className='subscription-upsell__text-add-bottles'>{` ${upsellBottleCount} more `}</span>
                        bottles to your subscription to get
                        <span className='subscription-upsell__text-discount'>{` ${upsellPercent}% off `}</span>
                        on your subsequent orders!
                    </div>
                }
                {this._renderUpsellCTA(this.props.editSubscriptionLink)}
            </div>
        );
    }

    private _renderUpsellCTA(link: IEditSubscriptionLinkData | undefined): JSX.Element | null {
        if (link && link.linkUrl.destinationUrl && link.linkText) {
            return (
                <a
                    href={link.linkUrl.destinationUrl}
                    aria-label={link.ariaLabel}
                    className='subscription-upsell__button'
                >
                    {link.linkText}
                </a>
            );
        }
        return null;
    }
}