import * as React from 'react';

import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { ICoreContext } from '@msdyn365-commerce/core';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import RangeRefineItem, { RangeRefineItemType } from './range-refine-item';
import RefineItem from './refine-item';
import { IRefineItemToggleNotification } from './refine-item-toggle-notification';
import { IRefineItemCommonProps } from './refine-item.props.common';
import { findMatchingRefinementCriterion, ProductRefinerTypeValue, ProductRefinerValueDataTypeValue } from './utilities';

/**
 * Properties associated with the RefineSubmenu component
 */
export interface IRefinePulldownProps {
    tempRangeTypeTODO: RangeRefineItemType;
    minValueSliderThumbAriaLabel?: string;
    maxValueSliderThumbAriaLabel?: string;
    productRefinerHierarchy: IProductRefinerHierarchy;
    selectedRefinerValues: ProductRefinerValue[];
    refineItemCommonProps: IRefineItemCommonProps;
    isDisabled: boolean;
    context: ICoreContext;
    moduleId: string;
    moduleTypeName: string;
    highestLevelRefinerText?: string;
    buttonText: string | undefined;
    onUpdateRefiners(notfication: Readonly<IRefineItemToggleNotification>): void;
    urlBuilder(refiner: IRefineItemToggleNotification): string;
}

/**
 *
 * The RefineSubmenu component renders the submenu category and child items.
 * This computed observes the stateful category hierarchy object.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class RefinePulldown extends React.Component<IRefinePulldownProps> {

    constructor(props: IRefinePulldownProps) {
        super(props);

        this._onToggleItem = this._onToggleItem.bind(this);
    }

    public componentDidMount(): void {
        document.body && document.body.addEventListener('mousedown', this._handleClickOutsidePulldown);
    }

    public componentWillUnmount(): void {
        document.body && document.body.removeEventListener('mousedown', this._handleClickOutsidePulldown, false);
    }

    public render(): JSX.Element | null {
        const { productRefinerHierarchy, refineItemCommonProps } = this.props;

        if (!productRefinerHierarchy) {
            refineItemCommonProps.telemetry.error('Cannot render submenu without refiner hierarchy data');
        }

        const isRangeRefiner = (productRefinerHierarchy.DataTypeValue === ProductRefinerValueDataTypeValue.Range);
        let key = '';
        if (isRangeRefiner) {
            const selectedRefinementCriterion = findMatchingRefinementCriterion(productRefinerHierarchy.Values[0], this.props.selectedRefinerValues);
            key = selectedRefinementCriterion ? `($${selectedRefinementCriterion.LeftValueBoundString && parseFloat(selectedRefinementCriterion.LeftValueBoundString).toFixed(2)} - $${selectedRefinementCriterion.RightValueBoundString && parseFloat(selectedRefinementCriterion.RightValueBoundString).toFixed(2)})` : '';
        }

        return (
            <div className='ms-refine-submenu list-group'>
                <div
                    className={'ms-refine-submenu__toggle_expanded'}
                    aria-label={productRefinerHierarchy.KeyName || 'refiner.Name'}
                >
                    {productRefinerHierarchy.KeyName} {key}
                </div>
                {this._renderChildItems(productRefinerHierarchy)}
            </div>
        );
    }

    private _renderChildItems(productRefinerHierarchy: IProductRefinerHierarchy): JSX.Element | null {
        switch (productRefinerHierarchy.DataTypeValue) {
            case ProductRefinerValueDataTypeValue.Range:
            case ProductRefinerValueDataTypeValue.RangeInput:
                return this._renderRange(productRefinerHierarchy);
            default:
                return this._renderSingleMultiSelect(productRefinerHierarchy);
        }
    }

    private _renderSingleMultiSelect(productRefinerHierarchy: IProductRefinerHierarchy): JSX.Element | null {
        const { isDisabled, refineItemCommonProps, selectedRefinerValues, context } = this.props;
        const isSingleSelect = productRefinerHierarchy.RefinerTypeValue === ProductRefinerTypeValue.Single;
        const role = isSingleSelect ? { role: 'radiogroup' } : undefined;
        const refinerValuesList = this._getSortedValueList() || [];

        const listItems = refinerValuesList.map((child: ProductRefinerValue, index: number) => {
            if (!child) {
                refineItemCommonProps.telemetry.error(
                    `[refine-submenu] Could not render refine item for refiner ${productRefinerHierarchy.RecordId} (${productRefinerHierarchy.KeyName})`
                );
                return null;
            }

            const selectedRefinementCriterion = findMatchingRefinementCriterion(child, selectedRefinerValues);

            return (
                <RefineItem
                    parentProductRefinerHierarchy={productRefinerHierarchy}
                    productRefinerValue={child}
                    selectedRefinementCriterion={selectedRefinementCriterion}
                    refineItemCommonProps={refineItemCommonProps}
                    onToggle={this._onToggleItem}
                    urlBuilder={this.props.urlBuilder}
                    isDisabled={isDisabled}
                    key={index}
                    context={context}
                    moduleId={this.props.moduleId}
                    moduleTypeName={this.props.moduleTypeName}
                />
            );
        });
        return (
            <ul className='ms-refine-submenu__list' {...role} aria-label={productRefinerHierarchy.KeyName}>
                {listItems}
            </ul>
        );
    }

    private _renderRange(productRefinerHierarchy: IProductRefinerHierarchy): JSX.Element | null {
        const { isDisabled, refineItemCommonProps, selectedRefinerValues, context, minValueSliderThumbAriaLabel, maxValueSliderThumbAriaLabel } = this.props;
        const submenuClassNamePrefix = 'ms-refine-submenu__item list-group-item refine-submenu__item';
        const refinerValuesList = this._getSortedValueList() || [];
        const listItems = refinerValuesList.map((child: ProductRefinerValue, index: number) => {
            if (!child) {
                refineItemCommonProps.telemetry.error(
                    `Could not render refine item for refiner ${productRefinerHierarchy.RecordId} (${productRefinerHierarchy.KeyName})`
                );
                return null;
            }

            const selectedRefinementCriterion = findMatchingRefinementCriterion(child, selectedRefinerValues);

            // TODO BUGBUG 22424559 determine only from the DataTypeValue once live example is working (can test with the tempRangeTypeTODO derived from QSP until then)
            const rangeType = (productRefinerHierarchy.DataTypeValue === ProductRefinerValueDataTypeValue.RangeInput || this.props.tempRangeTypeTODO === 'input') ?
                'input' :
                'slider';
            const key = selectedRefinementCriterion ? `${selectedRefinementCriterion.LeftValueBoundString}-${selectedRefinementCriterion.RightValueBoundString}` : `not-selected-${index}`;
            return (
                <li className={`${submenuClassNamePrefix}--range`} key={index}>
                    <RangeRefineItem
                        parentProductRefinerHierarchy={productRefinerHierarchy}
                        productRefinerValue={child}
                        selectedRefinementCriterion={selectedRefinementCriterion}
                        refineItemCommonProps={refineItemCommonProps}
                        onToggle={this._onToggleItem}
                        urlBuilder={this.props.urlBuilder}
                        isDisabled={isDisabled}
                        rangeType={rangeType}
                        key={key}
                        context={context}
                        minValueSliderThumbAriaLabel={minValueSliderThumbAriaLabel}
                        maxValueSliderThumbAriaLabel={maxValueSliderThumbAriaLabel}
                        moduleId={this.props.moduleId}
                        moduleTypeName={this.props.moduleTypeName}
                    />
                </li>
            );
        });
        return <ul className='ms-refine-submenu__list list-unstyled'>{listItems}</ul>;
    }

    private _onToggleItem(itemToggleNotification: IRefineItemToggleNotification): void {
        this.props.onUpdateRefiners(itemToggleNotification);
    }

    // tslint:disable-next-line:no-any
    private _handleClickOutsidePulldown = (event: any) => {
        const pulldown:boolean = event.target.id ===  'refiner-pulldown-button';
        const pulldownElement = document.getElementById('refiner-pulldown-container');
        if (!pulldownElement?.contains(event.target) && !pulldown && pulldownElement?.className !== 'ms-search-result-container__refiner-pulldown-container pulldown-hide') {
            const buttonElement = document.getElementById('refiner-pulldown-button');
            if (buttonElement) {
                buttonElement.classList.toggle('refiner-pulldown-button-expanded');
                this._getPulldownText(this._getActiveRefinersText(this.props.selectedRefinerValues), this.props.buttonText);
            }
            if (pulldownElement) {
                pulldownElement.classList.toggle('pulldown-hide');
            }
        }
    };

    private _getPulldownText = (activeRefiners: string, buttonText: string | undefined): string => {
        const buttonElement = document.getElementById('refiner-pulldown-button');
        const spanElement = document.getElementById('refiner-pulldown-button-content');
        if (buttonElement && spanElement) {
            if (buttonElement.className === 'ms-search-result-container__refiner-pulldown-button refiner-pulldown-button-expanded' || !activeRefiners) {
                return spanElement.innerText = buttonText || '';
            } else {
                return spanElement.innerText = activeRefiners;
            }
        }
        return '';
    };

    private _getActiveRefinersText = (activeRefiners: ProductRefinerValue[]): string => {
        let formatedActiveRefiners = '';
        activeRefiners.forEach(refiner => {
            if (refiner.UnitText === 'USD') {
                formatedActiveRefiners += `$${refiner.LeftValueBoundString}-$${refiner.RightValueBoundString} / `;
            } else if (refiner.LeftValueBoundString) {
                formatedActiveRefiners += `${refiner.LeftValueBoundString} / `;
            }
        });
        // removes trailing slash and whitespace
        formatedActiveRefiners = formatedActiveRefiners.substring(0, formatedActiveRefiners.length - 2);
        return formatedActiveRefiners;
    };

    private _getSortedValueList(): ProductRefinerValue[] {
        const sortedList = this.props.productRefinerHierarchy.Values.sort((nextOption, curOption) => {
            const curOptionName =
                nextOption.LeftValueBoundString || nextOption.RightValueBoundString || '';
            const nextOptionName =
                curOption.LeftValueBoundString || curOption.RightValueBoundString || '';

            return curOptionName.localeCompare(nextOptionName);
        });

        const dividedList = {
            options: [] as ProductRefinerValue[],
            topLevel: [] as ProductRefinerValue[]
        };
        sortedList.forEach(option => {
            const optionName = option.LeftValueBoundString || option.RightValueBoundString || '';

            if (optionName === this.props.highestLevelRefinerText) {
                dividedList.topLevel.push(option);
            } else {
                dividedList.options.push(option);
            }
        });

        return [...dividedList.topLevel, ...dividedList.options];
    }
}

export default RefinePulldown;