import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { RatingComponent } from '@msdyn365-commerce/components';
import { IComponent, IComponentProps, ICoreContext, IGridSettings, IImageData, IImageSettings, Image, msdyn365Commerce, RichTextComponent } from '@msdyn365-commerce/core';
import { ICartState, ICheckoutState } from '@msdyn365-commerce/global-state';
import { AsyncResult, AttributeValue, ProductAvailableQuantity, ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import React from 'react';
import AcclaimRatingsDisplay from '../modules/acclaim-ratings/acclaim-ratings-display';
import { ISmweProductCollectionProps } from '../modules/smwe-product-collection/smwe-product-collection.props.autogenerated';
import { ISmweSearchResultContainerProps } from '../modules/smwe-search-result-container/smwe-search-result-container.props.autogenerated';
import { CartUtilities } from '../Utilities/cart-utils';
import { BusLoader, LoadBus } from '../Utilities/event-bus';
import ProductLightbox, { IProductLightboxProps } from '../Utilities/product-lightbox';
import { filterCartLines } from '../Utilities/subscription-manager';

export interface ISmweSearchResultContainerParentProps extends ISmweSearchResultContainerProps<{}> {
    categoryHierarchy: CategoryHierarchy | undefined;
}

export interface ISmweProductCollectionParentProps extends ISmweProductCollectionProps<{}> {
    categoryHierarchy: undefined;
}

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    checkoutState?: AsyncResult<ICheckoutState>;
    cart?: AsyncResult<ICartState>;
    displayQuantitySlider?: boolean;
    disableQuantitySlider?: boolean;
    maxAdditionLimit?: number;
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    subscriptionBtnText?: string;
    parentProps: ISmweSearchResultContainerParentProps | ISmweProductCollectionParentProps;
    addToCart?: boolean;
    addToCartText?: string;
    giftCardText?: string;
    addToCartMessage?: string;
    showPrice?: boolean;
    availability?: ProductAvailableQuantity | undefined;
    clubPrice?: ProductPrice | undefined;
    showQuantityAsDropdown?: boolean;
}

export interface IProductComponent extends IComponent<IProductComponentProps> { }

const PriceComponentActions = {
};
let qtyAddedToCart: number = 0;

// tslint:disable-next-line:cyclomatic-complexity
const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    typeName,
    id,
    parentProps,
    checkoutState,
    subscriptionBtnText,
    displayQuantitySlider,
    showPrice
}) => {
    const {
        showStarRating,
        launchLightboxButtonText,
        productLightboxHref,
        productLightboxWidth,
        productLightboxHeight,
        productLightboxClassName,
        productLightboxIframeClassName,
        cardBanner
    } = parentProps.config;
    const product = data.product;
    const recordId = product?.RecordId;
    if (!product) {
        return null;
    }

    const productLightboxProps: IProductLightboxProps = {
        id: '',
        buttonText: launchLightboxButtonText,
        productLightboxHref: productLightboxHref,
        width: productLightboxWidth,
        height: productLightboxHeight,
        lightboxClassName: productLightboxClassName,
        iframeClassName: productLightboxIframeClassName,
        context: parentProps.context
    };

    if (productLightboxProps) {
        productLightboxProps.id = `product-placement-${recordId}`;
        const domainUrl = _getEndpoint(context && context.request.url.requestUrl.toString());
        if (productLightboxProps.productLightboxHref && productLightboxProps.productLightboxHref.indexOf('?') >= 0) {
            productLightboxProps.iframeSrc = `${domainUrl}${productLightboxProps.productLightboxHref}&productId=${recordId}`;
        } else {
            productLightboxProps.iframeSrc = `${domainUrl}${productLightboxProps.productLightboxHref}?productId=${recordId}`;
        }
    }

    let tastingNotes: AttributeValue | undefined;
    let appellation: AttributeValue | undefined;
    let banner: AttributeValue | undefined;
    let productType: AttributeValue | undefined;
    if (product.AttributeValues) {
        product.AttributeValues.forEach(attribute => {
            switch (attribute.Name) {
                case 'Tasting Notes':
                    tastingNotes = attribute;
                    break;
                case 'SLR Appellation':
                    appellation = attribute;
                    break;
                case 'Appellation':
                    appellation = attribute;
                    break;
                case cardBanner:
                    banner = attribute;
                    break;
                case 'Product Type':
                    productType = attribute;
                    break;
                default:
            }
        });
    }
    const ariaLabel = `${(appellation && appellation.TextValue) ? appellation.TextValue : ''} ${product.Name ? product.Name : ''} product page`;
    const imageProductClass = productType && productType.TextValue ? `product-type-${productType.TextValue.replace(/\s+/g, '-').toLowerCase()}` : '';
    const productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, parentProps.categoryHierarchy);
    const isGiftCard = product.ItemId?.toLowerCase() === 'giftcard';
    let imageUrl = product.PrimaryImageUrl;
    if (isGiftCard && parentProps.app.config.giftCardImage) {
        imageUrl = parentProps.app.config.giftCardImage;
    }
    return (
        <>
            <a href={productUrl} aria-label={ariaLabel} className={`msc-product__details product-placement-content ${imageProductClass}`}>
                <div className='msc-product__image'>
                    {renderProductPlacementImage(imageSettings, context.request.gridSettings, imageUrl, product.Name)}
                    <div className='product-details'>
                        {banner && renderAttribute(banner, 'product-details-banner')}
                        <i className='fas fa-chevron-right' />
                        <div className='product-details-header'>
                            {renderAttribute(appellation, 'product-attribute appellation')}
                            <h2 className='product-placement__item-title'>{product.Name}</h2>
                        </div>
                        {renderAttribute(tastingNotes, 'product-attribute description')}
                        {showPrice && !displayQuantitySlider && product.Price && renderPrice(product.Price)}
                        {recordId && renderAcclaim(recordId)}
                        {showStarRating && renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)}
                    </div>
                </div>
            </a>
            {renderProductLightboxButton(productLightboxProps)}
        </>
    );
};

function renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
    if (!avgRating) {
        return null;
    }

    const numRatings = totalRatings && totalRatings.toString() || undefined;

    return (
        <RatingComponent
            context={context}
            id={id}
            typeName={typeName}
            avgRating={avgRating}
            ratingCount={numRatings}
            readOnly={true}
            ariaLabel={ariaLabel || ''}
            data={{}}
        />
    );
}

function renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, altText?: string): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    return (
        <Image {...img} {...imageProps} loadFailureBehavior='empty' />
    );
}

function renderAttribute(attribute: AttributeValue | undefined, className: string | ''): JSX.Element | null {
    return (
        <div className={className}>
            {attribute && <RichTextComponent
                className='product-attribute-value'
                text={(attribute.TextValue !== undefined ? attribute.TextValue : '')}
            />}
        </div>
    );
}

// Render acclaim ratings if any
// @FIXME/dg: Hardcoding the count and date/reviewer settings is bad!
function renderAcclaim(recordId: number): JSX.Element | null {
    return (
        <AcclaimRatingsDisplay productID={recordId} numberToShow={1} showReviewer={true} showDate={false} />
    );
}

function renderPrice(price: number, className?: string, clubPrice?: number): JSX.Element | null {
    return (
        <div className={classnames('product-price', className)}>
            <span className='sr-only'>Regular Price ${price.toFixed(2).replace(/\.00$/,'')} Club Price ${clubPrice?.toFixed(2).replace(/\.00$/,'')}</span>
            <span aria-hidden='true' className='product-component-wrapper-price-default'>${price.toFixed(2).replace(/\.00$/,'')}</span>
            {clubPrice && <span aria-hidden='true' className='product-component-wrapper-price-club'>${clubPrice.toFixed(2).replace(/\.00$/,'')} Club</span>}
        </div>
    );
}

function _getEndpoint(url: string | undefined): string | null {
    if (url) {
        const urlParts = url.split('/');
        return url.indexOf('//') > -1 ? `${urlParts[0]}//${urlParts[2]}` : urlParts[0];
    }

    return null;
}

function renderProductLightboxButton(productLightboxProps: IProductLightboxProps | undefined): JSX.Element | null {
    if (!productLightboxProps) {
        return null;
    }

    return (
        <React.Fragment>
            <ProductLightbox {...productLightboxProps} />
        </React.Fragment>
    );
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<IProductComponent>(
    'Product',
    { component: ProductCard, ...PriceComponentActions }
);

interface IProductComponentWrapperData {
    quantity: number;
    disable: boolean;
    cartMessage: string;
}

/**
 * class definition containing product component for
 * add to cart state
 */
@LoadBus('product-component-wrapper')
class ProductComponentWrapper extends React.Component<IProductComponentProps, IProductComponentWrapperData> {

    private bus: BusLoader | undefined;
    private addToCartSubId: number;
    private finishAddToCartSubId: number;

    constructor(props: IProductComponentProps) {
        super(props);
        this.state = {
            quantity: 1,
            disable: false,
            cartMessage: ''
        };
        this._decreaseCartQuantity = this._decreaseCartQuantity.bind(this);
        this._increaseCartQuantity = this._increaseCartQuantity.bind(this);
        this._onQuantityChange = this._onQuantityChange.bind(this);
        this._onClickAddToCart = this._onClickAddToCart.bind(this);
        this._onClickAddToSubscription = this._onClickAddToSubscription.bind(this);

        this.addToCartSubId = this.bus?.subscribe('adding-to-cart', () => { this.setState({ disable: true }); }).id!;
        this.finishAddToCartSubId = this.bus?.subscribe('finished-adding-to-cart', () => { this.setState({ disable: false }); }).id!;
    }

    public componentWillUnmount(): void {
        this.bus?.unsubscribe(this.addToCartSubId);
        this.bus?.unsubscribe(this.finishAddToCartSubId);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public render(): JSX.Element | null {
        if (this.props.data.product && this.props.data.product.RecordId && this.props.displayQuantitySlider) {
            const attributes: AttributeValue[] | undefined = this.props.data.product.AttributeValues;
            const productType: AttributeValue | undefined = attributes?.find(attribute => attribute.Name === 'Product Type');
            const bottleSize: AttributeValue | undefined = attributes?.find(attribute => attribute.Name === 'Wine Bottle Size');
            const imageProductClass = productType?.TextValue ? `product-type-${productType.TextValue.replace(/\s+/g, '-').toLowerCase()}` : '';
            const { subscriptionBtnText } = this.props;
            const outOfStock = this.props.availability?.AvailableQuantity === undefined || this.props.availability?.AvailableQuantity > this.props.context.app.config.outOfStockThreshold ? false : true;
            const availability = this.props.availability?.AvailableQuantity && this.props.availability.AvailableQuantity - this.props.context.app.config.outOfStockThreshold;
            const productUrl = getProductPageUrlSync(this.props.data.product.Name || '', this.props.data.product.RecordId, this.props.context && this.props.context.actionContext, this.props.parentProps.categoryHierarchy);
            const showQuantityAsDropdown:boolean = this.props.showQuantityAsDropdown || false;
            const maxQuantity: number | undefined = availability || 10;
            const quantityControl = this._generateQuantityControl(this.props.data.product.RecordId, maxQuantity, this.state.quantity, showQuantityAsDropdown);

            if (this.props.giftCardText && bottleSize && bottleSize.TextValue === 'Gift Card') {
                return (
                    <React.Fragment>
                        <ProductComponent {...this.props} />
                        <div className={classnames('product-component-wrapper', imageProductClass, 'product-component-wrapper-giftcard')}>
                            <a
                                href={productUrl}
                                aria-label={this.props.giftCardText}
                                className='product-component-wrapper-giftcard-link'
                            >
                                {this.props.giftCardText}
                            </a>
                        </div>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    <ProductComponent {...this.props} />
                    <div className={classnames('product-component-wrapper', imageProductClass)}>
                        {this.props.data.product.Price && renderPrice(this.props.data.product.Price, 'product-component-wrapper-price', this.props.clubPrice?.CustomerContextualPrice)}
                        <div className='product-component-wrapper-quantity-group' hidden={outOfStock}>
                            <button className='product-component-wrapper-decrease-button' onClick={this._decreaseCartQuantity}>-</button>
                            <label className='sr-only' htmlFor={`quantity-${this.props.data.product.RecordId.toString()}`}>Quantity</label>
                            {quantityControl}
                            <button className='product-component-wrapper-increase-button' onClick={this._increaseCartQuantity}>+</button>
                        </div>
                        {this.props.addToCart && this.props.addToCartText && (
                            <div className='product-component-wrapper-add-to-cart'>
                                <button
                                    className={outOfStock ? 'product-component-wrapper-add-to-cart-out-of-stock' : 'product-component-wrapper-add-to-cart-button'}
                                    disabled={this.state.disable || outOfStock}
                                    onClick={this._onClickAddToCart}
                                >
                                    {outOfStock ? 'Out of stock' : this.props.addToCartText}
                                </button>
                                <div className={`product-component-wrapper-add-to-cart-message ${this.state.cartMessage}`} hidden={!this.state.cartMessage}>
                                    {this.state.cartMessage === 'success' && `${qtyAddedToCart} ${qtyAddedToCart === 1 ? 'item' : 'items'} ${this.props.addToCartMessage}`}
                                    {availability && this.state.cartMessage === 'error' && `Quantity available: ${availability}`}
                                    {/* Start Mixed Cart Bandaid */}
                                    {this.state.cartMessage === 'mixedcart' && 'Gift cards and events may not be combined with wine or merchandise orders. Please remove them from your cart and place a separate order.'}
                                    {/* End Mixed Cart Bandaid */}
                                </div>
                            </div>
                            )
                        }
                        {
                            this.props.checkoutState && subscriptionBtnText && (
                                <div className='product-component-wrapper-add-to-subscription'>
                                    <button
                                        className={outOfStock ? 'product-component-wrapper-add-to-cart-out-of-stock' : 'product-component-wrapper-add-to-subscription-button'}
                                        disabled={this.state.disable || (this._isAdditionInvalid && !!this.props.disableQuantitySlider) || outOfStock}
                                        onClick={this._onClickAddToSubscription}
                                    >
                                        {outOfStock ? 'Out of stock' : subscriptionBtnText}
                                    </button>
                                    <div className={`product-component-wrapper-add-to-cart-message ${this.state.cartMessage}`} hidden={!this.state.cartMessage}>
                                        {this.state.cartMessage === 'success' && `${qtyAddedToCart} ${qtyAddedToCart === 1 ? 'item' : 'items'} ${this.props.addToCartMessage}`}
                                        {availability && this.state.cartMessage === 'error' && `Quantity available: ${availability}`}
                                        {/* Start Mixed Cart Bandaid */}
                                        {this.state.cartMessage === 'mixedcart' && 'Gift cards and events may not be combined with wine or merchandise orders. Please remove them from your cart and place a separate order.'}
                                        {/* End Mixed Cart Bandaid */}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </React.Fragment>
            );
        } else {
            return <ProductComponent {...this.props} />;
        }
    }

    private _generateMenu = (quantity: number | undefined) => {
        const nodes = [];

        if (quantity !== undefined) {
            for (let i = 1; i <= quantity; i++) {
                // tslint:disable-next-line:react-a11y-role-has-required-aria-props
                nodes.push(<option className='product-component__quantity__select-menu__item' value={i}>{i}</option>);
            }
        }

        return nodes;
    };

    private _generateQuantityControl = (productId: number | undefined, maxQuantity: number | undefined, currentQuantity: number, showAsDropdown:boolean = false): JSX.Element => {
        if (showAsDropdown && productId !== undefined) {
            return (<select aria-label="Quantity To Purchase" id={`quantity-${productId.toString()}`}
                    className='product-component__quantity__select-menu' value={currentQuantity} onChange={this._onQuantityChange}>
                    {
                        this._generateMenu(maxQuantity)
                    }
                </select>);
        } else {
            return (
                /* tslint:disable-next-line: react-a11y-role-has-required-aria-props react-this-binding-issue */
                <input className='product-component-wrapper-quantity' type='number' value={currentQuantity} max={maxQuantity} onChange={this._onQuantityChange} />
            );
        }
    };

    private async _onClickAddToCart(): Promise<void> {
        const { data: { product }, context } = this.props;
        const cartQty = (this.props.cart?.result?.cart.TotalItems || 0) + this.state.quantity;
        qtyAddedToCart = this.state.quantity;

        this.bus?.publish('adding-to-cart');
        await onAddToCartAction(context, product!.RecordId, this.state.quantity);
        this.bus?.publish('finished-adding-to-cart');

        // Mixed Cart Bandaid - Remove when no longer needed
        // Checking if cart contains a gift card. Because gift cards are not added in the PLP, no need to check for the
        // other way around. If that changes, mixed cart check needs to be rewritten.

        /* Start Mixed Cart Bandaid */
        const mixedCart = !!this.props.cart?.result?.cart?.CartLines?.find(
            item => item.IsGiftCardLine ||
                item.DeliveryMode === "40.5" // 40.5 responds to events i believe, so use it here
        );
        /* End Mixed Cart Bandaid */
        if (cartQty === this.props.cart?.result?.cart.TotalItems) {
            this.setState({ cartMessage: 'success' });
            setTimeout(() => { this.setState({ cartMessage: '', quantity: 1 }); }, 2000);
        /* Start Mixed Cart Bandaid */
        } else if (mixedCart) {
            this.setState({ cartMessage: 'mixedcart' });
        /* End Mixed Cart Bandaid */
        } else {
            this.setState({ cartMessage: 'error' });
        }
    }

    private async _onClickAddToSubscription(): Promise<void> {
        const { data: { product }, context } = this.props;
        const cartQty = (this.props.cart?.result?.cart.TotalItems || 0) + this.state.quantity;
        qtyAddedToCart = this.state.quantity;

        this.bus?.publish('adding-to-cart');
        await onSubscribeAction(context, product!.RecordId, this.state.quantity, this.props.cart);
        this.bus?.publish('finished-adding-to-cart');

        // Mixed Cart Bandaid - Remove when no longer needed
        // Checking if cart contains a gift card. Because gift cards are not added in the PLP, no need to check for the
        // other way around. If that changes, mixed cart check needs to be rewritten.

        /* Start Mixed Cart Bandaid */
        const mixedCart = !!this.props.cart?.result?.cart?.CartLines?.find(
            item => item.IsGiftCardLine ||
                item.DeliveryMode === "40.5" // 40.5 responds to events i believe, so use it here
        );
        /* End Mixed Cart Bandaid */

        if (cartQty === this.props.cart?.result?.cart.TotalItems) {
            this.setState({ cartMessage: 'success' });
            setTimeout(() => { this.setState({ cartMessage: '', quantity: 1 }); }, 2000);
        /* Start Mixed Cart Bandaid */
        } else if (mixedCart) {
            this.setState({ cartMessage: 'mixedCart' });
        /* End Mixed Cart Bandaid */
        } else {
            this.setState({ cartMessage: 'error' });
        }
    }

    private _increaseCartQuantity(): void {
        this.setState({ quantity: this.state.quantity + 1 });
    }

    private _decreaseCartQuantity(): void {
        this.setState({ quantity: this.state.quantity > 1 ? this.state.quantity - 1 : this.state.quantity });
    }

    private _onQuantityChange({ target }: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void {
        if (parseInt(target.value, 10) < 1) {
            this.setState({ quantity: 1 });
        } else {
            this.setState({ quantity: parseInt(target.value, 10) });
        }
    }

    private get _isAdditionInvalid(): boolean {
        const cartQuantity = CartUtilities.countCartLineAmount(filterCartLines(this.props.cart?.result?.cart).subscriptionLines);
        return (this.state.quantity + cartQuantity) > (this.props.maxAdditionLimit || 12);
    }
}

async function onAddToCartAction(context: ICoreContext, recordId: number, amount: number | undefined): Promise<void> {

    return CartUtilities.elicitAddItemIdToCart({ recordId, amount, context });
}

async function onSubscribeAction(context: ICoreContext, recordId: number, amount?: number, cart?: AsyncResult<ICartState>): Promise<void> {
    // fetch attribute list and simple product

    return CartUtilities.elicitAddSubscriptionItemIdToCart({ context, recordId, amount }, cart!);
}

export default ProductComponentWrapper;