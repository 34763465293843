import { addThrottledEvent, Flipper, getCSS } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

interface ISingleSlideCarouselState {
    showPrevious: boolean;
    showNext: boolean;
    offset: number;
    activeItem: number;
}

interface ISingleSlideCarouselProps {
    flipperNextLabel?: string;
    flipperPrevLabel?: string;
    vertical?: boolean;
    touchScrollThreshold?: number;
    parentId?: string;
    useTabList?: boolean;
}

/**
 * SingleSlideCarousel
 */
export default class SingleSlideCarousel extends React.PureComponent<ISingleSlideCarouselProps, ISingleSlideCarouselState> {
    private static itemsSelector: string = 'msc-ss-carousel-item';
    private static verticalItemsSelector: string = 'msc-ss-carousel-vert-item';
    private direction: string;
    private item: HTMLElement | undefined;
    private slide: HTMLElement | undefined;
    private carousel: HTMLElement | undefined;
    private carouselSize: number = 0;
    private slideSize: number = 0;
    private itemSize: number = 0;
    private resizeThrottledEventHandler?: (event: Event) => void;
    private ref: React.RefObject<HTMLDivElement>;
    private slideRef: React.RefObject<HTMLUListElement>;
    private itemRef: React.RefObject<HTMLLIElement>;
    private id: string;
    private scrollStart: number | undefined;
    private scrollThreshold: number;

    constructor (props: ISingleSlideCarouselProps) {
        super(props);
        this._nextSlide = this._nextSlide.bind(this);
        this._previousSlide = this._previousSlide.bind(this);
        this._handleTouchStart = this._handleTouchStart.bind(this);
        this._handleTouchEnd = this._handleTouchEnd.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onResized = this._onResized.bind(this);
        this.state = { showPrevious: false, showNext: false, offset: 0, activeItem: 0 };
        this.ref = React.createRef();
        this.slideRef = React.createRef();
        this.itemRef = React.createRef();
        this.direction = 'left';
        this.id = props.parentId || '';
        this.scrollThreshold = this.props.touchScrollThreshold !== undefined ? this.props.touchScrollThreshold : 100;
    }

    public componentDidUpdate (): void {
        this.carousel = this.ref.current!;
        this.slide = this.slideRef.current!;
        this.item = this.itemRef.current!;
        this._setSizes();
        this._updateFlippers(this.state.offset);
    }

    public componentDidMount (): void {
        this.direction = this.props.vertical ? 'top' : 'left';
        this.carousel = this.ref.current!;
        this.slide = this.slideRef.current!;
        this.item = this.itemRef.current!;
        this._setSizes();
        this.resizeThrottledEventHandler =
            window && addThrottledEvent(window, 'resize', this._onResized as EventListener);
        this._updateFlippers(0);
    }

    public componentWillUnmount (): void {
        window && window.removeEventListener('resize', this.resizeThrottledEventHandler!, false);
    }

    public render (): JSX.Element {
        const isVert: boolean = this.props.vertical === true;
        const slidePositionStyle = {} as React.CSSProperties;
        const modifiedChildren = this._getClonedChildren(isVert);
        slidePositionStyle[this.direction] = `${this.state.offset}px`;
        const carouselClass = (isVert) ? 'msc-ss-carousel-vert' : 'msc-ss-carousel';
        const carouselSlideClass = (isVert) ? 'msc-ss-carousel-vert-slide' : 'msc-ss-carousel-slide';
        const overflowClass = (isVert) ? 'msc-ss-carousel-vert-strip' : 'msc-ss-carousel-strip';
        const previousFlipperGlyph = (isVert) ? 'msi-chevron-up' : 'msi-chevron-left';
        const nextFlipperGlyph = (isVert) ? 'msi-chevron-down' : 'msi-chevron-right';
        const previousFlipperClassName = (isVert) ? 'msc-ss-carousel-vert__flipper' : 'msc-ss-carousel__flipper';
        const nextFlipperClassName = (isVert) ? 'msc-ss-carousel-vert__flipper msc-ss-carousel-vert__flipper--next' : 'msc-ss-carousel__flipper msc-ss-carousel__flipper--next';
        const disableClassName = !this.state.showPrevious || !this.state.showNext ? 'disabled' : null;
        return (
            <div
                className={carouselClass}
                onTouchStart={this._handleTouchStart}
                onTouchEnd={this._handleTouchEnd}
            >
                <Flipper
                    id={`flipperPreviousButton_${this.id}`}
                    tabIndex={-1}
                    glyphProps={{ className: previousFlipperGlyph }}
                    onClick={this._previousSlide}
                    className={!this.state.showPrevious ? `${previousFlipperClassName} ${disableClassName}`: `${previousFlipperClassName}`}
                    aria-hidden={true}
                    aria-label={this.props.flipperPrevLabel}
                    disabled={!this.state.showPrevious ? true : false}
                    displayTooltip={this.props.flipperPrevLabel && this.props.flipperPrevLabel.length > 0 ? true : false}
                />
                <div ref={this.ref} className={`${overflowClass}`}>
                    <ul className={carouselSlideClass} style={slidePositionStyle} ref={this.slideRef} role={this.props.useTabList ? 'tablist' : 'list'}>
                        {modifiedChildren}
                    </ul>
                </div>
                <Flipper
                    id={`flipperNextButton_${this.id}`}
                    tabIndex={-1}
                    glyphProps={{ className: nextFlipperGlyph }}
                    onClick={this._nextSlide}
                    className={!this.state.showNext ? `${nextFlipperClassName} ${disableClassName}`: `${nextFlipperClassName}`}
                    aria-hidden={true}
                    aria-label={this.props.flipperNextLabel}
                    disabled={!this.state.showNext? true : false}
                    displayTooltip={this.props.flipperNextLabel && this.props.flipperNextLabel.length > 0 ? true : false}
                />
            </div>
        );
    }

    private _getClonedChildren (isVert: boolean): React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>[] {
        return React.Children.map(this.props.children, (child: React.ReactNode, index: number) => {
            const castChild = (child as React.ReactChild) as React.ReactElement<HTMLLIElement>;
            if (index === 1) {
                return React.cloneElement(castChild, {
                    className: `${isVert ? SingleSlideCarousel.verticalItemsSelector : SingleSlideCarousel.itemsSelector} ${(this.state.activeItem) === index ? 'active' : ''} ${castChild.props.className}`,
                    // @ts-ignore
                    ref: this.itemRef,
                    onFocus: this._onFocus
                });
            }
            return React.cloneElement(castChild, {
                className: `${isVert ? SingleSlideCarousel.verticalItemsSelector : SingleSlideCarousel.itemsSelector} ${(this.state.activeItem) === index ? 'active' : ''} ${castChild.props.className}`,
                // @ts-ignore
                onFocus: this._onFocus
            });
        });
    }

    private _nextSlide (): void {
        this._moveSingleSlide(true);
    }

    private _previousSlide (): void {
        this._moveSingleSlide(false);
    }

    private _handleTouchStart(evt: React.TouchEvent<HTMLDivElement>): void {
        if (evt.touches.length === 0) {
            this.scrollStart = undefined;
        } else {
            this.scrollStart = this.props.vertical === true ? evt.touches[0].screenY : evt.touches[0].screenX;
        }
    }

    private _handleTouchEnd(evt: React.TouchEvent<HTMLDivElement>): void {
        if (evt.changedTouches.length > 0 && this.scrollStart !== undefined) {
            const newTarget: number = this.props.vertical === true ? evt.changedTouches[0].screenY : evt.changedTouches[0].screenX;

            const delta = newTarget - this.scrollStart;

            if (delta > this.scrollThreshold) {
                this._previousSlide();
            }

            if (delta < -this.scrollThreshold) {
                this._nextSlide();
            }
        }

        this.scrollStart = undefined;

        return;
    }

    private _setActiveItem (offset: number): void {
        if (offset === 0) {
            this.setState({ activeItem: 0 });
        } else {
            this.setState({ activeItem: Math.abs(Math.round(offset / this.itemSize)) });
        }
    }

    private _moveSingleSlide (next: boolean): void {
        let currentOffset = parseInt(getCSS(this.slide!, this.direction), 10);
        const carouselSize = this.carouselSize;

        let maxScrollCount = Math.floor(carouselSize / (this.itemSize));
        const directionModifier = next ? -1 : 1;
        currentOffset = !isNaN(currentOffset) && typeof currentOffset === 'number' ? currentOffset : 0;

        if (maxScrollCount === 0) {
            maxScrollCount = 1;
        }

        maxScrollCount = carouselSize % (this.itemSize) === 0 ? maxScrollCount - 1 : maxScrollCount;
        maxScrollCount = Math.max(maxScrollCount, 1);
        const maxScrollDistance = maxScrollCount * (this.itemSize);

        let distanceToEdge = next ? this.slideSize - carouselSize + currentOffset : Math.abs(currentOffset);
        distanceToEdge = Math.max(0, distanceToEdge);

        const offset =
            maxScrollDistance <= distanceToEdge
                ? maxScrollDistance * directionModifier + currentOffset
                : distanceToEdge * directionModifier + currentOffset;

        this._setActiveItem(offset);
        this._updateFlippers(offset);
    }

    private _setSizes (): void {
        const item = this.item;
        if (!!item) {
            this.itemSize = this.props.vertical ? item.scrollHeight : item.scrollWidth;
        } else {
            this.itemSize = 0;
        }

        this.carouselSize = this._calculateCarouselSize();
        this.slideSize = this.slide ? (this.props.vertical ? this.slide.scrollHeight : this.slide.scrollWidth) : 0;
    }

    private _calculateCarouselSize (): number {
        if (!this.carousel) {
            return 0;
        }

        const carouselStyle = getComputedStyle(this.carousel);

        const padding = this.props.vertical ? parseFloat(carouselStyle.paddingTop || '')  + parseFloat(carouselStyle.paddingBottom || '')
            : parseFloat(carouselStyle.paddingLeft || '')  + parseFloat(carouselStyle.paddingRight || '');

        return this.props.vertical ? this.carousel.clientHeight - padding : this.carousel.clientWidth - padding;
    }

    private _canScrollPrevious (offset: number): boolean {
        return !isNaN(offset) && offset !== 0;
    }

    private _canScrollNext (offset: number): boolean {
        if (this.carouselSize + Math.abs(offset) >= this.slideSize) {
            return false;
        }

        return true;
    }

    private _updateFlippers (offset: number): void {
        this.setState({
            showPrevious: this._canScrollPrevious(offset),
            showNext: this._canScrollNext(offset),
            offset: offset
        });
    }

    private _scrollItemIntoView (item: HTMLElement): void {
        const carouselSize = this.carouselSize;
        let offset = (this.props.vertical) ? item.offsetTop : item.offsetLeft;
        let updateOffset = false;

        const left = parseInt(getCSS(this.slide!, 'left'), 10) || 0;
        const top = parseInt(getCSS(this.slide!, 'top'), 10) || 0;

        if (this.props.vertical) {
            if (top < 0 && -top > offset) {
                if (offset !== 0) {
                    offset = -offset + 1;
                }
                updateOffset = true;
            } else if (top + offset > carouselSize - this.itemSize) {
                offset = carouselSize - this.itemSize - offset - 1;
                updateOffset = true;
            }
        } else if (this.direction === 'left') {
            if (left < 0 && -left > offset) {
                if (offset !== 0) {
                    offset = -offset + 1;
                }
                updateOffset = true;
            } else if (left + offset > carouselSize - this.itemSize) {
                offset = carouselSize - this.itemSize - offset - 1;
                updateOffset = true;
            }
        }

        if (updateOffset) {
            this._updateFlippers(offset);

            if (this.props.vertical) {
                setTimeout(() => {
                    (this.slide as Node).parentElement!.scrollTop = 0;
                },         0);
            } else  {
                setTimeout(() => {
                    (this.slide as Node).parentElement!.scrollLeft = 0;
                },         0);
            }
        }
    }

    private _onFocus (event: React.FocusEvent): void {
        const target = event.currentTarget as HTMLElement;
        this._scrollItemIntoView(target);
    }

    private _onCarouselResized (): void {
        this._setSizes();
        let offset = parseInt(getCSS(this.slide!, this.direction), 10);

        // Ensure the carousel slide fits across the entire alotted space
        if (!isNaN(offset) && offset < 0 && this.slideSize + offset < this.carouselSize) {
            offset = Math.min(0, this.carouselSize - this.slideSize);
        }

        this._updateFlippers(offset);
    }

    private _onResized = (): void => {
        this._onCarouselResized();
    };
}
