import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { ISearchResultModalViewProps, Title } from '../../../modules/smwe-search-result-container/components';
import { IRefineMenuViewProps, ISearchResultContainerViewProps, ISortByViewProps, ITitleViewProps } from '../../../modules/smwe-search-result-container/smwe-search-result-container';

const renderSort = (props: ISortByViewProps, showSortBy: boolean): JSX.Element | null => {
    const { SortingContainer, sortByDropDown } = props;
    if (sortByDropDown && showSortBy) {
        return (
            <Node {...SortingContainer}>
                {sortByDropDown}
            </Node>
        );
    }
    return null;
};

const renderRefiner = (props: IRefineMenuViewProps): JSX.Element | null => {
    const { refiners, RefineMenuContainer, RefinerSectionContainer } = props;
    if (refiners) {
        return (
            <Node {...RefinerSectionContainer}>
                <Node {...RefineMenuContainer}>
                    {refiners.map((submenu, index) => (
                        <React.Fragment key={index}>
                            {submenu}
                        </React.Fragment>
                    ))}
                </Node>
            </Node>
        );
    }
    return null;
};

const createModalBody = (props: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps, showSortBy: boolean): JSX.Element | null => {
    if (sortByDropDown) {
        return React.cloneElement(props.modalBody, {}, renderSort(sortByDropDown, showSortBy), renderRefiner(refineMenu));
    }
    return null;
};

const createSearchResultModal = (modalProps: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps, showSortBy: boolean): JSX.Element => {
    return React.cloneElement(modalProps.modal, {}, modalProps.modalHeader, createModalBody(modalProps, refineMenu, sortByDropDown, showSortBy), modalProps.modalFooter);
};

const renderTitle = (props: ITitleViewProps, showResultPrefix: boolean, categoryNamePrefix: string): JSX.Element | null => {
    const { title, TitleContainer } = props;
    const titlePrefix = <Title className='ms-search-result__collection-title-prefix' text={categoryNamePrefix} />;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h2>
                    {showResultPrefix && titlePrefix}
                    {title.titleText}
                </h2>
            </Node>
        );
    }
    return null;
};

const renderTitleCount = (props: ITitleViewProps): JSX.Element | null => {
    const { title, TitleContainer } = props;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h5>
                    {title.titleCount}
                </h5>
            </Node>
        );
    }
    return null;
};

const SmweSearchResultContainerView: React.FC<ISearchResultContainerViewProps> = props => {
    const { SearchResultContainer, products, pagination, ProductsContainer, ProductSectionContainer, choiceSummary, isMobile, modalToggle, searchResultModal, TitleViewProps,
        refineMenu, sortByOptions, CategoryNavContainer, RefineAndProductSectionContainer, errorMessage,
        categoryDescription } = props;
    // customization for 1.0
    const { showRefiners, showSortBy, showResultCount, categoryNamePrefix } = props.config;
    if (isMobile) {
        return (
            <Module {...SearchResultContainer}>
                <Node {...ProductSectionContainer}>
                    <div className='ms-search-result-container__title-header'>
                        {TitleViewProps && renderTitle(TitleViewProps, showResultCount!, categoryNamePrefix!)}
                        {categoryDescription}
                    </div>
                    <div className='ms-search-result-container__filter-sort'>
                        {showRefiners && choiceSummary}
                        {showRefiners && modalToggle}
                        {showRefiners && createSearchResultModal(searchResultModal, refineMenu, sortByOptions, showSortBy!)}
                    </div>
                    <Node {...ProductsContainer}>
                        {errorMessage}
                        {products}
                    </Node>
                    {pagination}
                </Node>
            </Module>
        );
    }
    return (
        <Module {...SearchResultContainer}>
            {showResultCount && TitleViewProps && <Node {...CategoryNavContainer}>
                {renderTitleCount(TitleViewProps)}
            </Node>}
            <Node {...RefineAndProductSectionContainer}>
                {showRefiners && refineMenu && renderRefiner(refineMenu)}
                <Node {...ProductSectionContainer}>
                    <div className='ms-search-result-container__title-header'>
                        {TitleViewProps && renderTitle(TitleViewProps, showResultCount!, categoryNamePrefix!)}
                        {categoryDescription}
                    </div>
                    <div className='ms-search-result-container__filter-sort'>
                        {showRefiners && choiceSummary}
                        {showSortBy && sortByOptions && renderSort(sortByOptions, showSortBy)}
                    </div>
                    <Node {...ProductsContainer}>
                        {errorMessage}
                        {products}
                    </Node>
                    {pagination}
                </Node>
            </Node>
        </Module>
    );
};

export default SmweSearchResultContainerView;
